import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync, IfFulfilled, IfRejected } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import {
  addInputComment,
  approveInput,
  deleteInput,
  downloadInputData,
  downloadInputTemplate,
  loadClients,
  loadInput,
  loadInputEvents,
  updateInput,
  uploadInputData,
} from '../../api';
import { ROUTE_INPUTS_OVERVIEW } from '../../routes';
import { formatDate } from '../../utils';
import { ConfirmModal } from '../../components/Modals';
import { FetchError } from '../../components/FetchError';
import { EditInputDetailsPage, InputDetailsPage } from './components';

export const InputDetailsContainer = () => {
  const history = useHistory();
  const { inputId } = useParams();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  // promise fns
  const inputReq = useAsync({ promiseFn: loadInput, inputId });
  const eventsReq = useAsync({ promiseFn: loadInputEvents, inputId });
  const clientsReq = useAsync({ promiseFn: loadClients });

  // proxy fns
  const approveProxy = async (args) => {
    try {
      await approveInput(...args);
      inputReq.reload();
      eventsReq.reload();
    } catch (err) {
      throw err;
    }
  };

  const addCommentProxy = async (args) => {
    try {
      await addInputComment(...args);
      eventsReq.reload();
    } catch (err) {
      throw err;
    }
  };

  const deleteProxy = async (args) => {
    try {
      await deleteInput(...args);
    } catch (err) {
      throw err;
    }
    history.push(ROUTE_INPUTS_OVERVIEW);
  };

  const updateInputProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateInput(...args);
      setIsEditEnabled(false);
      inputReq.reload();
      eventsReq.reload();
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
    setIsSaving(false);
  };

  const uploadDataProxy = async (args) => {
    try {
      await uploadInputData(...args);
      eventsReq.reload();
    } catch (err) {
      throw err;
    }
  };

  const downloadDataProxy = async (args) => {
    try {
      const res = await downloadInputData(...args);
      window.open(res.url, '_blank');
    } catch (err) {
      throw err;
    }
  };

  const downloadTemplateProxy = async (args) => {
    try {
      const res = await downloadInputTemplate(...args);
      window.open(res.url, '_blank');
    } catch (err) {
      throw err;
    }
  };

  // defer fns
  const deleteReq = useAsync({ deferFn: deleteProxy });
  const saveReq = useAsync({ deferFn: updateInputProxy });
  const approveReq = useAsync({ deferFn: approveProxy });
  const addCommentReq = useAsync({ deferFn: addCommentProxy });
  const uploadDataReq = useAsync({ deferFn: uploadDataProxy });
  const downloadDataReq = useAsync({ deferFn: downloadDataProxy });
  const downloadTemplateReq = useAsync({ deferFn: downloadTemplateProxy });

  // callbacks
  const handleEdit = React.useCallback(() => {
    setIsEditEnabled(true);
  }, [setIsEditEnabled]);

  const handleCancel = React.useCallback(() => {
    setIsEditEnabled(false);
  }, [setIsEditEnabled]);

  const handleApprove = React.useCallback(() => {
    approveReq.run({ inputId });
  }, [approveReq, inputId]);

  const handleUpdateEvents = React.useCallback(() => {
    eventsReq.reload();
  }, [eventsReq]);

  const handleSave = React.useCallback(
    (input) => {
      saveReq.run({
        inputId,
        input: {
          name: input.data.name,
          recurring: input.data.recurring ? input.data.frequency : 'oneOff',
          firstDueDate: formatDate(input.data.firstDueDate),
          client: input.data.client,
          status: input.data.status || '',
          template: input.data.template,
        },
        files: input.data.files,
      });
    },
    [saveReq, inputId],
  );

  const handleDelete = React.useCallback(() => {
    setShowDeleteConfirm(true);
  }, [setShowDeleteConfirm]);

  const handleDeleteCancel = React.useCallback(() => {
    setShowDeleteConfirm(false);
  }, [setShowDeleteConfirm]);

  const handleDeleteConfirm = React.useCallback(() => {
    deleteReq.run({ inputId });
    setShowDeleteConfirm(false);
  }, [inputId, deleteReq, setShowDeleteConfirm]);

  const handleAddComment = React.useCallback(
    (comment) => {
      addCommentReq.run({ inputId, comment });
    },
    [addCommentReq, inputId],
  );

  const handleUploadData = React.useCallback(
    (files) => {
      uploadDataReq.run({ inputId, files });
    },
    [uploadDataReq, inputId],
  );

  const handleDownloadData = React.useCallback(
    (fileId) => {
      downloadDataReq.run({ inputId, fileId });
    },
    [downloadDataReq, inputId],
  );

  const handleDownloadTemplate = React.useCallback(() => {
    downloadTemplateReq.run({ inputId });
  }, [downloadTemplateReq, inputId]);

  if (inputReq.isLoading) {
    return <Loader message="Loading details" />;
  }
  if (clientsReq.isLoading) {
    return <Loader message="Loading clients" />;
  }

  // TODO: revert to rendering the edit mode in the InputDetailsPage?
  return (
    <>
      <IfRejected state={inputReq}>
        {(error) => (
          <FetchError
            message={`Failed to fetch input data from server. ${error.message}`}
          />
        )}
      </IfRejected>
      <IfRejected state={deleteReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={saveReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={approveReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={addCommentReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={uploadDataReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={downloadDataReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={downloadTemplateReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfFulfilled state={inputReq}>
        {(inputData) =>
          isEditEnabled ? (
            <>
              <IfRejected state={clientsReq}>
                {(error) => (
                  <FetchError
                    message={`Failed to fetch clients from server. ${error.message}`}
                  />
                )}
              </IfRejected>
              <IfFulfilled state={clientsReq}>
                {(clientsData) => (
                  <EditInputDetailsPage
                    input={inputData}
                    clients={clientsData.result}
                    isSaving={isSaving}
                    onSave={handleSave}
                    onCancel={handleCancel}
                  />
                )}
              </IfFulfilled>
            </>
          ) : (
            <InputDetailsPage
              input={inputData}
              events={eventsReq?.data?.events || []}
              isLoadingEvents={eventsReq.isLoading}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onApprove={handleApprove}
              onAddComment={handleAddComment}
              onUpdateEvents={handleUpdateEvents}
              onUploadData={handleUploadData}
              onDownloadData={handleDownloadData}
              onDownloadTemplate={handleDownloadTemplate}
            />
          )
        }
      </IfFulfilled>
      {showDeleteConfirm && (
        <ConfirmModal
          message="Are you sure you wish to delete this input?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </>
  );
};
