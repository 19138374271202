import React from 'react';
import PropTypes from 'prop-types';
import { Card, Label } from '@fcg-tech/regtech-components';
import { TextField } from '@fcg-tech/regtech-formik-components';

import { Field, FieldError, FormGroup, FormSection } from '../Form';

const validateName = (value) => {
  let error;
  if (!value) {
    error = 'Name is required';
  }
  return error;
};

const validateInternalReference = (value) => {
  let error;
  if (!value) {
    error = 'Internal reference is required';
  }
  return error;
};

export const EditClientSection = ({ errors }) => (
  <Card>
    <Card.Body>
      <Card.Title>Details</Card.Title>
      <FormSection>
        <FormSection.Column>
          <FormGroup>
            <Label column htmlFor="new-client-name">
              Name
            </Label>
            <Field
              component={TextField}
              type="text"
              id="new-client-name"
              name="data.name"
              validate={validateName}
            />
            <FieldError error={errors?.data?.name} />
          </FormGroup>
          <FormGroup>
            <Label column htmlFor="new-client-internal-reference">
              FCG Internal reference
            </Label>
            <Field
              component={TextField}
              type="text"
              id="new-client-internal-reference"
              name="data.internalReference"
              validate={validateInternalReference}
            />
            <FieldError error={errors?.data?.internalReference} />
          </FormGroup>
        </FormSection.Column>
      </FormSection>
    </Card.Body>
  </Card>
);

EditClientSection.propTypes = {
  errors: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      internalReference: PropTypes.string,
    }),
  }),
};

EditClientSection.defaultProps = {
  errors: null,
};
