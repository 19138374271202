import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { REPORT_FORM_DATA } from '../../../constants';
import { ROUTE_REPORTS_OVERVIEW } from '../../../routes';
import { clientShape } from '../../../propTypes';
import { EditContext } from '../../../components/EditContext';
import { EditReportSection } from '../../../components/PageSections';
import { SaveActionGroup } from '../../../components/ActionGroups';

export const NewReportPage = ({ clients, isSaving, onSave, onCancel }) => {
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(
    () =>
      updateBreadcrumbs([
        { label: 'Reports overview', url: ROUTE_REPORTS_OVERVIEW },
        'Create report',
      ]),
    [updateBreadcrumbs],
  );

  const report = React.useMemo(() => ({ ...REPORT_FORM_DATA }), []);

  return (
    <EditContext.Provider value>
      <Formik initialValues={report} onSubmit={onSave}>
        {({ handleSubmit, values, errors }) => (
          <Form>
            <PageLayout>
              <PageHeader title="Create report" breadcrumbs={breadcrumbs}>
                <SaveActionGroup
                  isSaving={isSaving}
                  onSave={handleSubmit}
                  onCancel={onCancel}
                />
              </PageHeader>
              <PageLayout.PaddedContent>
                <EditReportSection
                  report={values}
                  clients={clients}
                  errors={errors}
                />
              </PageLayout.PaddedContent>
            </PageLayout>
          </Form>
        )}
      </Formik>
    </EditContext.Provider>
  );
};

NewReportPage.propTypes = {
  clients: PropTypes.arrayOf(clientShape),
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

NewReportPage.defaultProps = {
  clients: [],
  isSaving: false,
  onSave: null,
  onCancel: null,
};
