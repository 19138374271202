import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Card,
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

const Container = styled.div``;

const MessagesList = styled.ul`
  margin: 0;
  padding: 0 0 0 2rem;
`;

const MessageItem = styled.li`
  margin-bottom: 1.2rem;
  font-size: 1.4rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const EmptyState = styled.li`
  font-size: 1.4rem;
  list-style: none;
`;

export const DashboardPage = ({ messages }) => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(() => updateBreadcrumbs([]), [updateBreadcrumbs]);

  return (
    <Container>
      <PageLayout>
        <PageHeader
          title="Dashboard"
          subtitle="Welcome to FCG Regulatory Reporting"
        />
        <PageLayout.PaddedContent>
          <Card>
            <Card.Body>
              <Card.Title>Notifications</Card.Title>
              {messages.length ? (
                <MessagesList>
                  {messages.map(({ id, message }) => (
                    <MessageItem key={id}>
                      <Link to="/">{message}</Link>
                    </MessageItem>
                  ))}
                </MessagesList>
              ) : (
                <EmptyState>No new notifications.</EmptyState>
              )}
            </Card.Body>
          </Card>
        </PageLayout.PaddedContent>
      </PageLayout>
    </Container>
  );
};

DashboardPage.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
};

DashboardPage.defaultProps = {
  messages: [],
};
