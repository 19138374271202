import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { ROUTE_CLIENTS_OVERVIEW } from '../../../routes';
import { clientShape } from '../../../propTypes';
import { checkPermissions } from '../../../components/AccessControl';
import { EditContext } from '../../../components/EditContext';
import {
  EditActionGroup,
  SaveActionGroup,
} from '../../../components/ActionGroups';
import { EditClientSection } from '../../../components/PageSections';

export const ClientDetailsPage = ({
  client,
  isEditEnabled,
  isSaving,
  onSave,
  onEdit,
  onDelete,
  onCancel,
}) => {
  const menuItems = [{ id: 'delete', label: 'Delete', callback: onDelete }];

  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(() => {
    updateBreadcrumbs([
      { label: 'Clients overview', url: ROUTE_CLIENTS_OVERVIEW },
      client.data.name,
    ]);
  }, [updateBreadcrumbs, client.data.name]);

  return (
    <EditContext.Provider value={isEditEnabled}>
      <Formik initialValues={client} onSubmit={onSave}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <PageLayout>
              <PageHeader title={client.data.name} breadcrumbs={breadcrumbs}>
                {isEditEnabled ? (
                  <SaveActionGroup
                    isSaving={isSaving}
                    onSave={handleSubmit}
                    onCancel={onCancel}
                  />
                ) : (
                  <EditActionGroup
                    menuItems={
                      checkPermissions(client.permissions, [
                        'reportiq:ClientDelete',
                      ])
                        ? menuItems
                        : []
                    }
                    canEdit={checkPermissions(client.permissions, [
                      'reportiq:ClientEdit',
                    ])}
                    onEdit={onEdit}
                  />
                )}
              </PageHeader>
              <PageLayout.PaddedContent>
                <EditClientSection />
              </PageLayout.PaddedContent>
            </PageLayout>
          </form>
        )}
      </Formik>
    </EditContext.Provider>
  );
};

ClientDetailsPage.propTypes = {
  client: clientShape,
  isEditEnabled: PropTypes.bool,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

ClientDetailsPage.defaultProps = {
  client: {},
  isEditEnabled: false,
  isSaving: false,
  onSave: null,
  onEdit: null,
  onDelete: null,
  onCancel: null,
};
