import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InfoCircle, UploadIcon } from '@fcg-tech/regtech-components';
import {
  formikFieldShape,
  formikFormShape,
} from '@fcg-tech/regtech-formik-components';

import { randId } from '../../utils';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  width: 100%;
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus + ${Label} {
    outline: 1px dotted #000;
  }
`;

const FileName = styled.div`
  flex: 1;
  border: 1px dashed rgba(59, 65, 81, 0.5);
  border-bottom-left-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
  border-right: 0;
  font-size: 1.4rem;
  padding: 1rem;
  text-align: left;
`;

const UploadIconContainer = styled.div`
  border-bottom-right-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  background-color: #000;
  color: #fff;
  padding: 0.6rem;
`;

const StyledUploadIcon = styled(UploadIcon)`
  width: 2.4rem;
  height: 2.4rem;
`;

const StyledInfoCircle = styled(InfoCircle)`
  margin-left: 1rem;
`;

export const FileInput = ({ field, form, value, info }) => {
  const id = React.useMemo(() => randId(), []);

  const handleChange = React.useCallback(
    (event) => {
      form.setFieldValue(field.name, event.target.files);
    },
    [field, form],
  );

  return (
    <Container>
      <Input type="file" id={id} onChange={handleChange} />
      <Label htmlFor={id}>
        <FileName>
          {(field?.value?.length ? field.value[0].name : value) ||
            'No file choosen'}
        </FileName>
        <UploadIconContainer>
          <StyledUploadIcon />
        </UploadIconContainer>
      </Label>
      {info && <StyledInfoCircle info={info} />}
    </Container>
  );
};

FileInput.propTypes = {
  field: formikFieldShape.isRequired,
  form: formikFormShape.isRequired,
  info: PropTypes.string,
  value: PropTypes.string,
};

FileInput.defaultProps = {
  info: null,
  value: null,
};
