import React from 'react';
import { Redirect } from 'react-router-dom';
import { parse } from 'query-string';
import { endLoginFlow } from '../api/auth';

export const AuthCallbackContainer = () => {
  const tokens = parse(window.location.hash);
  const state = window.localStorage.getItem(tokens.state);

  if (tokens.state && state) {
    window.localStorage.removeItem(tokens.state);
    if (tokens.id_token) {
      endLoginFlow(tokens.id_token);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(
      'Could not find the referenced state, this is an invalid request',
    );
    return <Redirect to="/" />;
  }

  // return <Redirect to={JSON.parse(state).returnUrl || '/'} />;
  return <Redirect to="/" />;
};
