import React from 'react';
import { useAsync, IfFulfilled, IfPending, IfRejected } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import { loadClients } from '../../api';
import { AppContext } from '../../components/AppContext';
import { AccessControl } from '../../components/AccessControl';
import { FetchError } from '../../components/FetchError';
import { ClientsOverviewPage } from './components';

export const ClientsOverviewContainer = () => {
  const { permissions } = React.useContext(AppContext);

  const clientsReq = useAsync({ promiseFn: loadClients });

  return (
    <AccessControl
      permissions={permissions}
      requiredPermissions={['reportiq:ClientList']}
      renderNoAccess={() => (
        <FetchError message="You don't currently have permission to list the clients." />
      )}
    >
      <IfPending state={clientsReq}>
        <Loader message="Loading clients" />;
      </IfPending>
      <IfRejected state={clientsReq}>
        {(error) => (
          <FetchError
            message={`Failed to fetch clients from server. ${error.message}`}
          />
        )}
      </IfRejected>
      <IfFulfilled state={clientsReq}>
        {(data) => <ClientsOverviewPage clients={data.result} />}
      </IfFulfilled>
    </AccessControl>
  );
};
