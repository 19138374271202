import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageLayout, useBreadcrumbs } from '@fcg-tech/regtech-components';
import {
  Calendar,
  AgendaEvent,
  Event,
  EventWrapper,
  MonthHeader,
  Toolbar,
} from '@fcg-tech/regtech-calendar';

import { calendarEventShape } from '../../../propTypes';

const Container = styled.div`
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const StyledPageLayout = styled(PageLayout)`
  height: inherit;
  width: inherit;
`;

const PaddedContent = styled(PageLayout.PaddedContent)`
  height: inherit;
`;

export const CalendarPage = ({ events }) => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(() => updateBreadcrumbs(['Calendar']), [updateBreadcrumbs]);

  return (
    <Container>
      <StyledPageLayout>
        <PaddedContent>
          <Calendar
            events={events.map((event) => ({
              ...event,
              start: new Date(event.date),
              end: new Date(event.date),
              allDay: true,
            }))}
            components={{
              event: Event,
              eventWrapper: EventWrapper,
              month: { header: MonthHeader },
              agenda: { event: AgendaEvent },
              toolbar: Toolbar,
            }}
          />
        </PaddedContent>
      </StyledPageLayout>
    </Container>
  );
};

CalendarPage.propTypes = {
  events: PropTypes.arrayOf(calendarEventShape),
};

CalendarPage.defaultProps = {
  events: [],
};
