import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync, IfFulfilled, IfRejected } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import {
  addReportComment,
  approveReport,
  deleteReport,
  downloadReportData,
  loadClients,
  loadReport,
  loadReportEvents,
  updateReport,
  uploadReportData,
} from '../../api';
import { ROUTE_REPORTS_OVERVIEW } from '../../routes';
import { formatDate } from '../../utils';
import { ConfirmModal } from '../../components/Modals';
import { FetchError } from '../../components/FetchError';
import { EditReportDetailsPage, ReportDetailsPage } from './components';

export const ReportDetailsContainer = () => {
  const history = useHistory();
  const { reportId } = useParams();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditEnabled, setEditEnabled] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  // promise fns
  const reportReq = useAsync({ promiseFn: loadReport, reportId });
  const eventsReq = useAsync({ promiseFn: loadReportEvents, reportId });
  const clientsReq = useAsync({ promiseFn: loadClients });

  // proxy fns
  const approveProxy = async (args) => {
    try {
      await approveReport(...args);
      reportReq.reload();
      eventsReq.reload();
    } catch (err) {
      throw err;
    }
  };

  const addCommentProxy = async (args) => {
    try {
      await addReportComment(...args);
      eventsReq.reload();
    } catch (err) {
      throw err;
    }
  };

  const deleteProxy = async (args) => {
    try {
      await deleteReport(...args);
    } catch (err) {
      throw err;
    }
    history.push(ROUTE_REPORTS_OVERVIEW);
  };

  const updateReportProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateReport(...args);
      setEditEnabled(false);
      reportReq.reload();
      eventsReq.reload();
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
    setIsSaving(false);
  };

  const uploadDataProxy = async (args) => {
    try {
      await uploadReportData(...args);
      eventsReq.reload();
    } catch (err) {
      throw err;
    }
  };

  const downloadDataProxy = async (args) => {
    try {
      const res = await downloadReportData(...args);
      window.open(res.url, '_blank');
    } catch (err) {
      throw err;
    }
  };

  // defer fns
  const deleteReq = useAsync({ deferFn: deleteProxy });
  const saveReq = useAsync({ deferFn: updateReportProxy });
  const approveReq = useAsync({ deferFn: approveProxy });
  const addCommentReq = useAsync({ deferFn: addCommentProxy });
  const uploadDataReq = useAsync({ deferFn: uploadDataProxy });
  const downloadDataReq = useAsync({ deferFn: downloadDataProxy });

  // callbacks
  const handleEdit = React.useCallback(() => {
    setEditEnabled(true);
  }, [setEditEnabled]);

  const handleCancel = React.useCallback(() => {
    setEditEnabled(false);
  }, [setEditEnabled]);

  const handleDelete = React.useCallback(() => {
    setShowDeleteConfirm(true);
  }, [setShowDeleteConfirm]);

  const handleDeleteCancel = React.useCallback(() => {
    setShowDeleteConfirm(false);
  }, [setShowDeleteConfirm]);

  const handleDeleteConfirm = React.useCallback(() => {
    deleteReq.run({ reportId });
    setShowDeleteConfirm(false);
  }, [reportId, deleteReq, setShowDeleteConfirm]);

  const handleUpdateEvents = React.useCallback(() => {
    eventsReq.reload();
  }, [eventsReq]);

  const handleSave = React.useCallback(
    (report) => {
      saveReq.run({
        reportId,
        report: {
          type: report.data.type,
          client: report.data.client,
          recurring: report.data.recurring ? report.data.frequency : 'oneOff',
          firstDueDate: formatDate(report.data.firstDueDate),
          referencePeriodEnd: formatDate(report.data.referencePeriodEnd),
          status: report.data.status || '',
        },
      });
    },
    [saveReq, reportId],
  );

  const handleApprove = React.useCallback(() => {
    approveReq.run({ reportId });
  }, [approveReq, reportId]);

  const handleAddComment = React.useCallback(
    (comment) => {
      addCommentReq.run({ reportId, comment });
    },
    [addCommentReq, reportId],
  );

  const handleUploadData = React.useCallback(
    (files) => {
      uploadDataReq.run({ reportId, files });
    },
    [uploadDataReq, reportId],
  );

  const handleDownloadData = React.useCallback(
    (fileId) => {
      downloadDataReq.run({ reportId, fileId });
    },
    [downloadDataReq, reportId],
  );

  if (reportReq.isLoading) {
    return <Loader message="Loading details" />;
  }
  if (clientsReq.isLoading) {
    return <Loader message="Loading clients" />;
  }

  // TODO: revert to rendering the edit mode in the ReportDetailsPage?
  return (
    <>
      <IfRejected state={reportReq}>
        {(error) => (
          <FetchError
            message={`Failed to fetch report data from the server. ${error.message}`}
          />
        )}
      </IfRejected>
      <IfRejected state={saveReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={deleteReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={approveReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={addCommentReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={uploadDataReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={downloadDataReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfFulfilled state={reportReq}>
        {(reportData) =>
          isEditEnabled ? (
            <>
              <IfRejected state={clientsReq}>
                {(error) => (
                  <FetchError
                    message={`Failed to fetch clients from server. ${error.message}`}
                  />
                )}
              </IfRejected>
              <IfFulfilled state={clientsReq}>
                {(clientsData) => (
                  <EditReportDetailsPage
                    report={reportData}
                    clients={clientsData.result}
                    isSaving={isSaving}
                    onSave={handleSave}
                    onCancel={handleCancel}
                  />
                )}
              </IfFulfilled>
            </>
          ) : (
            <ReportDetailsPage
              report={reportData}
              events={eventsReq?.data?.events || []}
              isLoadingEvents={eventsReq.isLoading}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onApprove={handleApprove}
              onAddComment={handleAddComment}
              onUpdateEvents={handleUpdateEvents}
              onUploadData={handleUploadData}
              onDownloadData={handleDownloadData}
            />
          )
        }
      </IfFulfilled>
      {showDeleteConfirm && (
        <ConfirmModal
          message="Are you sure you wish to delete this report?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </>
  );
};
