import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useSortableTable,
} from '@fcg-tech/regtech-components';

import { formatDate } from '../../../utils';
import { reportShape } from '../../../propTypes';
import { constructRoute, ROUTE_REPORT_DETAILS } from '../../../routes';

const EmptyTableState = styled.div`
  flex: 1;
  text-align: center;
`;

const statusMap = {
  started: 'Started',
  upcoming: 'Upcoming',
  approved: 'Approved',
};

const tableCells = [
  { id: 'type', label: 'Report type' },
  { id: 'client', label: 'Client' },
  { id: 'referencePeriodEnd', label: 'Period end' },
  { id: 'firstDueDate', label: 'Due date' },
  { id: 'modified', label: 'Latest action' },
  { id: 'status', label: 'Status' },
];

const sorter = (a, b, key, ascending) => {
  const aFlatten = { ...a.data, ...a.metadata };
  const bFlatten = { ...b.data, ...b.metadata };
  const aa = ascending ? aFlatten : bFlatten;
  const bb = ascending ? bFlatten : aFlatten;

  if (key === 'client') {
    return aa[key]?.name.localeCompare(bb[key]?.name);
  }

  if (!aa[key]) return -1;
  if (!bb[key]) return 1;
  return aa[key].localeCompare(bb[key]);
};

const makeTableHeaderCellProps = (key, { sortedColumnKey, ...props }) => ({
  sortable: true,
  sortKey: key,
  isSorted: sortedColumnKey === key,
  ...props,
});

export const ReportsTable = ({ reports }) => {
  const history = useHistory();

  const { sortedItems, ...sortProps } = useSortableTable({
    column: 'type',
    sorter,
    items: reports,
  });

  const handleClickRow = React.useCallback(
    (id) =>
      history.push(constructRoute(ROUTE_REPORT_DETAILS, { reportId: id })),
    [history],
  );

  return (
    <Table>
      <TableHead>
        <TableRow clickable>
          {tableCells.map(({ id, label }) => (
            <TableCell key={id} {...makeTableHeaderCellProps(id, sortProps)}>
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedItems.length ? (
          sortedItems.map(({ metadata, data }) => {
            const flattenItem = { ...metadata, ...data };
            return (
              <TableRow
                key={metadata.id}
                clickable
                onClick={() => handleClickRow(metadata.id)}
              >
                {tableCells.map(({ id: cellId }) => (
                  <TableCell key={cellId}>
                    {(() => {
                      switch (cellId) {
                        case 'client':
                          return flattenItem[cellId]?.name || '';
                        case 'referencePeriodEnd':
                        case 'firstDueDate':
                        case 'modified':
                          return flattenItem[cellId]
                            ? formatDate(flattenItem[cellId])
                            : '';
                        case 'status':
                          return statusMap[flattenItem[cellId]] || '';
                        default:
                          return flattenItem[cellId] || '';
                      }
                    })()}
                  </TableCell>
                ))}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={tableCells.length}>
              <EmptyTableState>No reports found.</EmptyTableState>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

ReportsTable.propTypes = {
  reports: PropTypes.arrayOf(reportShape),
};

ReportsTable.defaultProps = {
  reports: [],
};
