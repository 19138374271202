import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Modal } from '@fcg-tech/regtech-components';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2.4rem 0 2.4rem 0;
  min-width: 40vw;
  font-size: 1.4rem;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ErrorModal = ({ message, onRequestClose }) => {
  return (
    <Modal>
      <Modal.Body>
        <Modal.Title>Error</Modal.Title>
        <Content>{message || 'An error occurred'}</Content>

        <ButtonRow>
          <Button onClick={onRequestClose}>Close</Button>
        </ButtonRow>
      </Modal.Body>
    </Modal>
  );
};

ErrorModal.propTypes = {
  message: PropTypes.string,
  onRequestClose: PropTypes.func,
};

ErrorModal.defaultProps = {
  message: '',
  onRequestClose: null,
};
