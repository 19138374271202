import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useSortableTable,
} from '@fcg-tech/regtech-components';

import { formatDate } from '../../../utils';
import { inputShape } from '../../../propTypes';
import { constructRoute, ROUTE_INPUT_DETAILS } from '../../../routes';

const EmptyTableState = styled.div`
  flex: 1;
  text-align: center;
`;

const recurringMap = {
  oneOff: 'Once',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiAnnually: 'Semianually',
  annually: 'Annually',
};

const statusMap = {
  done: 'Done',
  upcoming: 'Upcoming',
  approved: 'Approved',
};

const tableCells = [
  { id: 'name', label: 'Name' },
  { id: 'client', label: 'Client' },
  { id: 'recurring', label: 'Recurring' },
  { id: 'firstDueDate', label: 'Due date' },
  { id: 'modified', label: 'Latest action' },
  { id: 'modifiedBy', label: 'Done by' },
  { id: 'status', label: 'Status' },
];

const sorter = (a, b, key, ascending) => {
  const aFlatten = { ...a.data, ...a.metadata };
  const bFlatten = { ...b.data, ...b.metadata };
  const aa = ascending ? aFlatten : bFlatten;
  const bb = ascending ? bFlatten : aFlatten;

  if (key === 'client') {
    return aa[key]?.name.localeCompare(bb[key]?.name);
  }

  if (!aa[key]) return -1;
  if (!bb[key]) return 1;
  return aa[key].localeCompare(bb[key]);
};

const makeTableHeaderCellProps = (key, { sortedColumnKey, ...props }) => ({
  sortable: true,
  sortKey: key,
  isSorted: sortedColumnKey === key,
  ...props,
});

export const InputsTable = ({ inputs }) => {
  const history = useHistory();

  const { sortedItems, ...sortProps } = useSortableTable({
    column: 'name',
    sorter,
    items: inputs,
  });

  const handleClickRow = React.useCallback(
    (id) => history.push(constructRoute(ROUTE_INPUT_DETAILS, { inputId: id })),
    [history],
  );

  return (
    <Table>
      <TableHead>
        <TableRow clickable>
          {tableCells.map(({ id, label }) => (
            <TableCell key={id} {...makeTableHeaderCellProps(id, sortProps)}>
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedItems.length ? (
          sortedItems.map(({ metadata, data }) => {
            const flattenItem = { ...metadata, ...data };
            return (
              <TableRow
                key={metadata.id}
                clickable
                onClick={() => handleClickRow(metadata.id)}
              >
                {tableCells.map(({ id: cellId }) => (
                  <TableCell key={cellId}>
                    {(() => {
                      switch (cellId) {
                        case 'client':
                          return flattenItem[cellId]?.name || '';
                        case 'recurring':
                          return recurringMap[flattenItem[cellId]];
                        case 'firstDueDate':
                        case 'modified':
                          return flattenItem[cellId]
                            ? formatDate(flattenItem[cellId])
                            : '';
                        case 'status':
                          return statusMap[flattenItem[cellId]] || '';
                        default:
                          return flattenItem[cellId] || '';
                      }
                    })()}
                  </TableCell>
                ))}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={tableCells.length}>
              <EmptyTableState>No inputs found.</EmptyTableState>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

InputsTable.propTypes = {
  inputs: PropTypes.arrayOf(inputShape),
};

InputsTable.defaultProps = {
  inputs: [],
};
