import React from 'react';
import PropTypes from 'prop-types';
import { isValid } from 'date-fns';
import { Card, Label } from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  DateField,
  Select,
  TextField,
} from '@fcg-tech/regtech-formik-components';

import { FREQUENCY_OPTIONS } from '../../constants';
import { getTooltip } from '../../utils';
import { clientShape } from '../../propTypes';
import { FileInput } from '../FileInput';
import { Field, FieldError, FormGroup, FormSection } from '../Form';

const validateName = (value) => {
  let error;
  if (!value) {
    error = 'Name is required';
  }
  return error;
};

const validateClient = (value) => {
  let error;
  if (!value) {
    error = 'Client is required';
  }
  return error;
};

const validateFrequency = (value) => {
  let error;
  if (!value) {
    error = 'Frequency is required';
  }
  return error;
};

const validateFirstDueDate = (value) => {
  let error;
  if (!value) {
    error = 'Due date is required';
  } else if (!isValid(new Date(value))) {
    error = 'Invalid date format';
  }
  return error;
};

const validateTemplate = (value) => {
  let error;
  if (!value) {
    error = 'Template is required';
  }
  return error;
};

export const EditInputSection = ({ clients, input, errors }) => {
  const clientItems = React.useMemo(
    () =>
      clients.map((client) => ({
        key: client.metadata.id.toString(),
        label: client.data.name,
      })),
    [clients],
  );

  return (
    <Card>
      <Card.Body>
        <Card.Title>Details</Card.Title>
        <FormSection>
          <FormSection.Column>
            <FormGroup>
              <Label column htmlFor="edit-input-name">
                Name
              </Label>
              <Field
                component={TextField}
                type="text"
                id="edit-input-name"
                name="data.name"
                validate={validateName}
                info={getTooltip('input.name')}
              />
              <FieldError error={errors?.data?.name} />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="edit-input-client">
                Client
              </Label>
              <Field
                component={Select}
                items={clientItems}
                id="edit-input-client"
                validate={validateClient}
                name="data.client.id"
                info={getTooltip('input.client')}
              />
              <FieldError error={errors?.data?.client?.id} />
            </FormGroup>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  Recurring
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="data.recurring"
                  info={getTooltip('input.recurring')}
                />
              </BooleanDecision.FieldContainer>
            </FormGroup>
          </FormSection.Column>
        </FormSection>
        <FormSection>
          {input?.data?.recurring ? (
            <FormSection.Column>
              <FormGroup>
                <Label column htmlFor="edit-input-frequency">
                  Frequency
                </Label>
                <Field
                  component={Select}
                  items={FREQUENCY_OPTIONS}
                  id="edit-input-frequency"
                  name="data.frequency"
                  validate={validateFrequency}
                  info={getTooltip('input.frequency')}
                />
                <FieldError error={errors?.data?.frequency} />
              </FormGroup>
            </FormSection.Column>
          ) : null}
          <FormSection.Column>
            <FormGroup>
              <Label column htmlFor="edit-input-first-due-date">
                {input && input.data.recurring ? 'First due date' : 'Due date '}
              </Label>
              <Field
                component={DateField}
                id="edit-input-first-due-date"
                name="data.firstDueDate"
                validate={validateFirstDueDate}
                info={getTooltip('input.firstDueDate')}
              />
              <FieldError error={errors?.data?.firstDueDate} />
            </FormGroup>
          </FormSection.Column>
        </FormSection>
        <FormSection>
          <FormSection.Column>
            <FormGroup>
              <Label column>Upload template</Label>
              <Field
                component={FileInput}
                name="data.files"
                validate={validateTemplate}
                value={input.data?.template?.name}
                info={getTooltip('input.template')}
              />
              <FieldError error={errors?.data?.files} />
            </FormGroup>
          </FormSection.Column>
        </FormSection>
      </Card.Body>
    </Card>
  );
};

EditInputSection.propTypes = {
  clients: PropTypes.arrayOf(clientShape),
  input: PropTypes.shape({
    data: PropTypes.shape({
      recurring: PropTypes.bool,
    }),
  }).isRequired,
  errors: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      client: PropTypes.shape({
        id: PropTypes.string,
      }),
      frequency: PropTypes.string,
      firstDueDate: PropTypes.string,
      files: PropTypes.string,
    }),
  }),
};

EditInputSection.defaultProps = {
  clients: [],
  errors: {},
};
