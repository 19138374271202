import React from 'react';
import PropTypes from 'prop-types';
import { Card, Label } from '@fcg-tech/regtech-components';
import { isValid } from 'date-fns';
import {
  BooleanDecision,
  DateField,
  Select,
  TextField,
} from '@fcg-tech/regtech-formik-components';

import { FREQUENCY_OPTIONS } from '../../constants';
import { getTooltip } from '../../utils';
import { clientShape } from '../../propTypes';
import { Field, FieldError, FormGroup, FormSection } from '../Form';

const validateType = (value) => {
  let error;
  if (!value) {
    error = 'Type is required';
  }
  return error;
};

const validateClient = (value) => {
  let error;
  if (!value) {
    error = 'Client is required';
  }
  return error;
};

const validateFrequency = (value) => {
  let error;
  if (!value) {
    error = 'Frequency is required';
  }
  return error;
};

const validateReferencePeriodEnd = (value) => {
  let error;
  if (!value) {
    error = 'Reference period end is required';
  } else if (!isValid(new Date(value))) {
    error = 'Invalid date format';
  }
  return error;
};

const validateFirstDueDate = (value) => {
  let error;
  if (!value) {
    error = 'First due date is required';
  } else if (!isValid(new Date(value))) {
    error = 'Invalid date format';
  }
  return error;
};

export const EditReportSection = ({ report, clients, errors }) => {
  const clientItems = React.useMemo(
    () =>
      clients.map((client) => ({
        key: client.metadata.id,
        label: client.data.name,
      })),
    [clients],
  );

  return (
    <Card>
      <Card.Body>
        <Card.Title>Details</Card.Title>
        <FormSection>
          <FormSection.Column>
            <FormGroup>
              <Label column htmlFor="edit-report-type">
                Type
              </Label>
              <Field
                component={TextField}
                type="text"
                id="edit-report-type"
                name="data.type"
                validate={validateType}
                info={getTooltip('report.type')}
              />
              <FieldError error={errors?.data?.type} />
            </FormGroup>
            <FormGroup>
              <Label column htmlFor="edit-report-client">
                Client
              </Label>
              <Field
                component={Select}
                items={clientItems}
                id="edit-report-client"
                name="data.client.id"
                validate={validateClient}
                info={getTooltip('report.client')}
              />
              <FieldError error={errors?.data?.client?.id} />
            </FormGroup>
            <FormGroup>
              <BooleanDecision.FieldContainer>
                <BooleanDecision.FieldLabel>
                  Recurring
                </BooleanDecision.FieldLabel>
                <Field
                  component={BooleanDecision}
                  name="data.recurring"
                  info={getTooltip('report.recurring')}
                />
              </BooleanDecision.FieldContainer>
            </FormGroup>
            {report?.data?.recurring ? (
              <FormGroup>
                <Label column htmlFor="edit-report-frequency">
                  Frequency
                </Label>
                <Field
                  component={Select}
                  items={FREQUENCY_OPTIONS}
                  id="edit-report-frequency"
                  name="data.frequency"
                  validate={validateFrequency}
                  info={getTooltip('report.frequency')}
                />
                <FieldError error={errors?.data?.frequency} />
              </FormGroup>
            ) : null}
          </FormSection.Column>
        </FormSection>
        <FormSection>
          <FormSection.Column>
            <FormGroup>
              <Label column htmlFor="edit-report-reference-period-end">
                Reference period end
              </Label>
              <Field
                component={DateField}
                id="edit-report-reference-period-end"
                name="data.referencePeriodEnd"
                validate={validateReferencePeriodEnd}
                info={getTooltip('report.referencePeriodEnd')}
              />
              <FieldError error={errors?.data?.referencePeriodEnd} />
            </FormGroup>
          </FormSection.Column>
          <FormSection.Column>
            <FormGroup>
              <Label column htmlFor="edit-report-first-due-date">
                {report?.data?.recurring ? 'First due date' : 'Due date'}
              </Label>
              <Field
                component={DateField}
                id="edit-report-first-due-date"
                name="data.firstDueDate"
                validate={validateFirstDueDate}
                info={getTooltip('report.firstDueDate')}
              />
              <FieldError error={errors?.data?.firstDueDate} />
            </FormGroup>
          </FormSection.Column>
        </FormSection>
      </Card.Body>
    </Card>
  );
};

EditReportSection.propTypes = {
  clients: PropTypes.arrayOf(clientShape),
  report: PropTypes.shape({
    data: PropTypes.shape({
      recurring: PropTypes.bool,
    }),
  }).isRequired,
  errors: PropTypes.shape({
    data: PropTypes.shape({
      type: PropTypes.string,
      client: PropTypes.shape({
        id: PropTypes.string,
      }),
      frequency: PropTypes.string,
      referencePeriodEnd: PropTypes.string,
      firstDueDate: PropTypes.string,
    }),
  }),
};

EditReportSection.defaultProps = {
  clients: [],
  errors: null,
};
