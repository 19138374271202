import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@fcg-tech/regtech-components';

export const SaveActionGroup = ({ isSaving, onSave, onCancel }) => (
  <>
    <Button onClick={onSave} disabled={isSaving}>
      {isSaving ? 'Saving...' : 'Save'}
    </Button>
    <Button onClick={onCancel} variant="outlined" color="secondary">
      Cancel
    </Button>
  </>
);

SaveActionGroup.propTypes = {
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

SaveActionGroup.defaultProps = {
  isSaving: false,
  onSave: null,
  onCancel: null,
};
