import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { CLIENT_SKELETON } from '../../../constants';
import { ROUTE_CLIENTS_OVERVIEW } from '../../../routes';
import { EditClientSection } from '../../../components/PageSections';
import { EditContext } from '../../../components/EditContext';
import { SaveActionGroup } from '../../../components/ActionGroups';

export const NewClientPage = ({ isSaving, onSave, onCancel }) => {
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(
    () =>
      updateBreadcrumbs([
        { label: 'Clients overview', url: ROUTE_CLIENTS_OVERVIEW },
        'Create client',
      ]),
    [updateBreadcrumbs],
  );

  const client = React.useMemo(() => ({ ...CLIENT_SKELETON }), []);

  return (
    <EditContext.Provider value>
      <Formik initialValues={client} onSubmit={onSave}>
        {({ handleSubmit, errors }) => (
          <Form>
            <PageLayout>
              <PageHeader title="Create client" breadcrumbs={breadcrumbs}>
                <SaveActionGroup
                  isSaving={isSaving}
                  onSave={handleSubmit}
                  onCancel={onCancel}
                />
              </PageHeader>
              <PageLayout.PaddedContent>
                <EditClientSection errors={errors} />
              </PageLayout.PaddedContent>
            </PageLayout>
          </Form>
        )}
      </Formik>
    </EditContext.Provider>
  );
};

NewClientPage.propTypes = {
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

NewClientPage.defaultProps = {
  isSaving: false,
  onSave: null,
  onCancel: null,
};
