/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  ChatBubbleIcon,
  UploadIcon,
  OkIcon,
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { FREQUENCY_ITEMS } from '../../../constants';
import { ROUTE_REPORTS_OVERVIEW } from '../../../routes';
import { formatDate } from '../../../utils';
import { reportShape } from '../../../propTypes';
import {
  checkPermissions,
  AccessControl,
} from '../../../components/AccessControl';
import { CommentModal } from '../../../components/Modals';
import { EditActionGroup } from '../../../components/ActionGroups';
import { EventsTableSection } from '../../../components/PageSections';

const Details = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
`;

const Detail = styled.li`
  margin: 0 0 1.4rem 0;
  font-size: 1.4rem;
`;

const DetailName = styled.span`
  font-weight: 600;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
  & > * + * {
    margin-left: 1rem;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

export const ReportDetailsPage = ({
  report,
  events,
  isLoadingEvents,
  onEdit,
  onDelete,
  onApprove,
  onAddComment,
  onUpdateEvents,
  onUploadData,
  onDownloadData,
}) => {
  const [showCommentModal, setShowCommentModal] = React.useState(false);
  const menuItems = [{ id: 'delete', label: 'Delete', callback: onDelete }];

  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(
    () =>
      updateBreadcrumbs([
        { label: 'Reports overview', url: ROUTE_REPORTS_OVERVIEW },
        report.data.type,
      ]),
    [updateBreadcrumbs, report.data.type],
  );

  const handleOpenCommentModal = React.useCallback(() => {
    setShowCommentModal(true);
  }, [setShowCommentModal]);

  const handleCommentConfirm = React.useCallback(
    (comment) => {
      if (comment.text) {
        onAddComment(comment);
        setShowCommentModal(false);
      }
    },
    [setShowCommentModal, onAddComment],
  );

  const handleCommentCancel = React.useCallback(() => {
    setShowCommentModal(false);
  }, [setShowCommentModal]);

  const handleUploadData = React.useCallback(
    (event) => {
      if (onUploadData) {
        onUploadData(event.target.files);
      }
    },
    [onUploadData],
  );

  return (
    <>
      <PageLayout>
        <PageHeader title={report.data.type} breadcrumbs={breadcrumbs}>
          <EditActionGroup
            menuItems={
              checkPermissions(report.permissions, ['reportiq:ReportDelete'])
                ? menuItems
                : []
            }
            canEdit={checkPermissions(report.permissions, [
              'reportiq:ReportEdit',
            ])}
            onEdit={onEdit}
          />
        </PageHeader>
        <PageLayout.PaddedContent>
          <Details>
            <Detail>
              <DetailName>Client:</DetailName> {report.data.client.name}
            </Detail>
            <Detail>
              <DetailName>Due date:</DetailName>{' '}
              {formatDate(report.data.firstDueDate)}
            </Detail>
            <Detail>
              <DetailName>Recurring:</DetailName>{' '}
              {FREQUENCY_ITEMS[report.data.recurring]}
            </Detail>
          </Details>
          <ButtonRow>
            <AccessControl
              permissions={report.permissions}
              requiredPermissions={['reportiq:ReportAddFile']}
            >
              <label htmlFor="upload-input-data">
                <Button
                  as="span"
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  icon={<UploadIcon />}
                >
                  Upload data
                </Button>
              </label>
              <HiddenInput
                id="upload-input-data"
                onChange={handleUploadData}
                type="file"
              />
            </AccessControl>
            <AccessControl
              permissions={report.permissions}
              requiredPermissions={['reportiq:ReportAddComment']}
            >
              <Button
                onClick={handleOpenCommentModal}
                color="secondary"
                size="medium"
                variant="outlined"
                icon={<ChatBubbleIcon />}
              >
                Comment
              </Button>
            </AccessControl>
            <AccessControl
              permissions={report.permissions}
              requiredPermissions={['reportiq:ReportApprove']}
            >
              <Button
                disabled={report.data.status === 'approved'}
                onClick={onApprove}
                size="medium"
                icon={<OkIcon />}
              >
                Approve
              </Button>
            </AccessControl>
          </ButtonRow>
          <EventsTableSection
            events={events}
            isLoadingEvents={isLoadingEvents}
            onUpdateEvents={onUpdateEvents}
            onDownloadData={onDownloadData}
          />
        </PageLayout.PaddedContent>
      </PageLayout>
      {showCommentModal && (
        <CommentModal
          onConfirm={handleCommentConfirm}
          onCancel={handleCommentCancel}
        />
      )}
    </>
  );
};

ReportDetailsPage.propTypes = {
  report: reportShape,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  isLoadingEvents: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onApprove: PropTypes.func,
  onAddComment: PropTypes.func,
  onUpdateEvents: PropTypes.func,
  onUploadData: PropTypes.func,
  onDownloadData: PropTypes.func,
};

ReportDetailsPage.defaultProps = {
  report: null,
  events: [],
  isLoadingEvents: false,
  onEdit: null,
  onDelete: null,
  onApprove: null,
  onAddComment: null,
  onUpdateEvents: null,
  onUploadData: null,
  onDownloadData: null,
};
