export const checkPermissions = (permissions, requiredPermissions) => {
  if (requiredPermissions?.length === 0) {
    return true;
  }

  return permissions?.some((permission) =>
    requiredPermissions.includes(permission),
  );
};

export const AccessControl = ({
  children,
  permissions,
  requiredPermissions,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(permissions, requiredPermissions);

  if (permitted) {
    return children;
  }

  return renderNoAccess();
};

AccessControl.defaultProps = {
  permissions: [],
  requiredPermissions: [],
  renderNoAccess: () => null,
};
