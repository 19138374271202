import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error.main};
    font-size: ${theme.typography.body.fontSize};
    font-size: 1.2rem;
    font-weight: ${theme.typography.body.fontWeight};
    font-family: ${theme.typography.body.fontFamily};
    margin-top: 0.5rem;
  `}
`;

export const FieldError = ({ error }) => {
  if (!error) {
    return null;
  }

  return <Container>{error}</Container>;
};

FieldError.propTypes = {
  error: PropTypes.string,
};

FieldError.defaultProps = {
  error: null,
};
