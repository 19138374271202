import styled from 'styled-components';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  align-items: stretch;
  flex: 1;

  &:last-child {
    margin-bottom: 0;
  }
`;
