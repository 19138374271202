import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Modal, Button } from '@fcg-tech/regtech-components';
import { TextArea } from '@fcg-tech/regtech-formik-components';

import { EditContext } from '../EditContext';
import { Field, FormGroup, FormSection } from '../Form';

const StyledFormSection = styled(FormSection)`
  align-items: center;
  min-width: 40vw;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const COMMENT_SKELETON = { text: '' };

export const CommentModal = ({ onConfirm, onCancel }) => (
  <Formik
    initialValues={COMMENT_SKELETON}
    onSubmit={(comment) => onConfirm(comment)}
  >
    {({ handleSubmit, values }) => (
      <EditContext.Provider value>
        <Modal>
          <Modal.Body>
            <Modal.Title>Comment</Modal.Title>
            <StyledFormSection>
              <FormSection.Column>
                <FormGroup>
                  <Field
                    component={TextArea}
                    name="text"
                    placeholder="Enter your comment here..."
                  />
                </FormGroup>
              </FormSection.Column>
            </StyledFormSection>
          </Modal.Body>
          <Modal.Footer>
            <ButtonRow>
              <Button disabled={!values.text} onClick={handleSubmit}>
                Post comment
              </Button>
              <Button onClick={onCancel} variant="outlined" color="secondary">
                Cancel
              </Button>
            </ButtonRow>
          </Modal.Footer>
        </Modal>
      </EditContext.Provider>
    )}
  </Formik>
);

CommentModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

CommentModal.defaultProps = {
  onConfirm: null,
  onCancel: null,
};
