import { apiFetch, localFetch } from './auth';

const USE_MOCK = process.env.REACT_APP_FEATURE_USE_MOCK === 'true';

const POST = {
  method: 'POST',
  headers: {
    'content-type': 'application/json',
  },
};

const PUT = {
  method: 'PUT',
  headers: {
    'content-type': 'application/json',
  },
};

const DELETE = {
  method: 'DELETE',
};

// #region templates
export const uploadToS3 = async (url, file) => {
  try {
    const res = await window.fetch(url, { method: 'PUT', body: file });
    return res;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
};

export const fetchTemplateUploadUrl = async ({ filename }, { signal } = {}) => {
  const res = await apiFetch('/templates/uploadUrl', {
    ...POST,
    body: JSON.stringify({ filename }),
    signal,
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const uploadTemplate = async ({ files }) => {
  const { id, putUrl } = await fetchTemplateUploadUrl({
    filename: files[0].name,
  });
  const res = await uploadToS3(putUrl, files[0]);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return id;
};
// #endregion

// #region inputs
export const loadInputs = async (_, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch('/mock/inputs/data.json');
  } else {
    res = await apiFetch('/inputs', { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const createInput = async ({ input, files }, { signal } = {}) => {
  if (!USE_MOCK) {
    const body = { ...input };
    if (files) {
      const id = await uploadTemplate({ files });
      body.template = { id };
    }
    const res = await apiFetch('/inputs', {
      ...POST,
      body: JSON.stringify(body),
      signal,
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const updateInput = async (
  { inputId, input, files },
  { signal } = {},
) => {
  if (!USE_MOCK) {
    const body = { ...input };
    if (files) {
      const id = await uploadTemplate({ files });
      body.template = { id };
    }
    const res = await apiFetch(`/inputs/${inputId}`, {
      ...PUT,
      body: JSON.stringify(body),
      signal,
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res;
  }

  throw new Error('Request not available in local mode.');
};

export const loadInput = async ({ inputId }, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch(`/mock/inputs/${inputId}/data.json`);
  } else {
    res = await apiFetch(`/inputs/${inputId}`, { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const deleteInput = async ({ inputId }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/inputs/${inputId}`, { ...DELETE, signal });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res;
  }

  throw new Error('Request not available in local mode.');
};

export const approveInput = async ({ inputId }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/inputs/${inputId}/approve`, {
      ...POST,
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const loadInputEvents = async ({ inputId }, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch(`/mock/inputs/${inputId}/events.json`);
  } else {
    res = await apiFetch(`/inputs/${inputId}/events`, { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const addInputComment = async (
  { inputId, comment },
  { signal } = {},
) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/inputs/${inputId}/comments`, {
      ...POST,
      body: JSON.stringify(comment),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const downloadInputTemplate = async ({ inputId }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/inputs/${inputId}/template`, { signal });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const fetechInputDataUploadUrl = async (
  { inputId, filename },
  { signal } = {},
) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/inputs/${inputId}/uploads`, {
      ...POST,
      body: JSON.stringify({ filename }),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const uploadInputData = async ({ inputId, files }, { signal } = {}) => {
  const { fileId, putUrl } = await fetechInputDataUploadUrl({
    inputId,
    filename: files[0].name,
  });
  const res = await uploadToS3(putUrl, files[0]);
  await apiFetch(`/inputs/${inputId}/uploads/${fileId}`, {
    ...PUT,
    body: JSON.stringify({ success: Boolean(res.ok) }),
    signal,
  });
};

export const downloadInputData = async (
  { inputId, fileId },
  { signal } = {},
) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/inputs/${inputId}/uploads/${fileId}`, {
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};
// #endregion

// #region reports
export const loadReports = async (_, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch('/mock/reports/data.json');
  } else {
    res = await apiFetch('/reports', { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const loadReport = async ({ reportId }, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch(`/mock/reports/${reportId}/data.json`);
  } else {
    res = await apiFetch(`/reports/${reportId}`, { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const createReport = async ({ report }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch('/reports', {
      ...POST,
      body: JSON.stringify(report),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const deleteReport = async ({ reportId }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/reports/${reportId}`, { ...DELETE, signal });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res;
  }

  throw new Error('Request not available in local mode.');
};

export const updateReport = async ({ reportId, report }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/reports/${reportId}`, {
      ...PUT,
      body: JSON.stringify(report),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res;
  }

  throw new Error('Request not available in local mode.');
};

export const approveReport = async ({ reportId }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/reports/${reportId}/approve`, {
      ...POST,
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const loadReportEvents = async ({ reportId }, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch(`/mock/reports/${reportId}/events.json`);
  } else {
    res = await apiFetch(`/reports/${reportId}/events`, { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const addReportComment = async (
  { reportId, comment },
  { signal } = {},
) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/reports/${reportId}/comments`, {
      ...POST,
      body: JSON.stringify(comment),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const fetechReportDataUploadUrl = async (
  { reportId, filename },
  { signal } = {},
) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/reports/${reportId}/uploads`, {
      ...POST,
      body: JSON.stringify({ filename }),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const uploadReportData = async (
  { reportId, files },
  { signal } = {},
) => {
  const { fileId, putUrl } = await fetechReportDataUploadUrl({
    reportId,
    filename: files[0].name,
  });
  const res = await uploadToS3(putUrl, files[0]);
  await apiFetch(`/reports/${reportId}/uploads/${fileId}`, {
    ...PUT,
    body: JSON.stringify({ success: Boolean(res.ok) }),
    signal,
  });
};

export const downloadReportData = async (
  { reportId, fileId },
  { signal } = {},
) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/reports/${reportId}/uploads/${fileId}`, {
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};
// #endregion

// #region clients
export const loadClients = async (_, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch(`/mock/clients/data.json`);
  } else {
    res = await apiFetch(`/clients`, { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const createClient = async ({ client }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/clients`, {
      ...POST,
      body: JSON.stringify(client),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res.json();
  }

  throw new Error('Request not available in local mode.');
};

export const deleteClient = async ({ clientId }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/clients/${clientId}`, { ...DELETE, signal });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res;
  }

  throw new Error('Request not available in local mode..');
};

export const loadClient = async ({ clientId }, { signal } = {}) => {
  let res;
  if (USE_MOCK) {
    res = await localFetch(`/mock/clients/${clientId}/data.json`);
  } else {
    res = await apiFetch(`/clients/${clientId}`, { signal });
  }

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const updateClient = async ({ clientId, client }, { signal } = {}) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/clients/${clientId}`, {
      ...PUT,
      body: JSON.stringify(client),
      signal,
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return res;
  }

  throw new Error('Request not available in local mode.');
};
// #endregion

// #region Calendar
export const loadCalendarEvents = async (_, { signal }) => {
  if (!USE_MOCK) {
    const res = await apiFetch(`/calendar/events`, {
      signal,
    });
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  }

  throw new Error('Request not available in local mode');
};
// #endregion
