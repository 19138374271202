import React from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { useAsync, IfFulfilled, IfPending, IfRejected } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import {
  constructRoute,
  ROUTE_INPUTS_OVERVIEW,
  ROUTE_INPUT_DETAILS,
} from '../../routes';
import { formatDate } from '../../utils';
import { createInput, loadClients } from '../../api';
import { AppContext } from '../../components/AppContext';
import { AccessControl } from '../../components/AccessControl';
import { FetchError } from '../../components/FetchError';
import { NewInputPage } from './components';

export const NewInputContainer = () => {
  const history = useHistory();
  const { permissions } = React.useContext(AppContext);
  const [isSaving, setIsSaving] = React.useState(false);

  // promise fns
  const clientsReq = useAsync({ promiseFn: loadClients });

  // proxy fns
  const saveInputProxy = async (args) => {
    setIsSaving(true);
    try {
      const { Location } = await createInput(...args);
      if (Location) {
        const match = matchPath(Location, { path: '/inputs/:inputId' });
        const { inputId } = match.params;
        history.push(constructRoute(ROUTE_INPUT_DETAILS, { inputId }));
      } else {
        setIsSaving(false);
      }
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
  };

  // defer fns
  const saveReq = useAsync({ deferFn: saveInputProxy });

  // callbacks
  const handleSave = React.useCallback(
    (input) => {
      saveReq.run({
        input: {
          name: input.data.name,
          client: input.data.client,
          recurring: input.data.recurring ? input.data.frequency : 'oneOff',
          firstDueDate: formatDate(input.data.firstDueDate),
          status: '',
        },
        files: input.data.files,
      });
    },
    [saveReq],
  );

  const handleCancel = React.useCallback(() => {
    history.push(ROUTE_INPUTS_OVERVIEW);
  }, [history]);

  return (
    <AccessControl
      permissions={permissions}
      requiredPermissions={['reportiq:InputAdd']}
      renderNoAccess={() => (
        <FetchError message="You don't have permission to create new inputs." />
      )}
    >
      <IfPending state={clientsReq}>
        <Loader message="Loading details" />;
      </IfPending>
      <IfRejected state={clientsReq}>
        {(error) => (
          <FetchError
            message={`Failed to fetch clients from server. ${error.message}`}
          />
        )}
      </IfRejected>
      <IfRejected state={saveReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfFulfilled state={clientsReq}>
        {(data) => (
          <NewInputPage
            clients={data.result}
            isSaving={isSaving}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        )}
      </IfFulfilled>
    </AccessControl>
  );
};
