import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  ChevronRightIcon,
  ExternalIcon,
  IconButton,
  Loader,
  RefreshIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow as DefaultTableRow,
  useSortableTable,
} from '@fcg-tech/regtech-components';

import { formatDate } from '../../utils';

const TableRow = styled(DefaultTableRow)`
  cursor: pointer;
`;

const TableCellRight = styled(TableCell)`
  justify-content: flex-end;
`;

const LoaderContainer = styled.div`
  margin-top: 1rem;
`;

const EmptyTableState = styled.div`
  flex: 1;
  text-align: center;
`;

const ExternalLink = styled.a`
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  & > svg {
    margin-left: 10px;
  }
`;

const UpdateButtonContainer = styled.div`
  margin-left: auto;
`;

const ExpandIcon = styled(({ expanded, ...props }) => (
  <ChevronRightIcon {...props} />
))`
  transform: rotate(${({ expanded }) => (expanded ? '-90deg' : '90deg')});
  transition: transform 0.1s linear;
`;

const StyledExternalIcon = styled(ExternalIcon)`
  width: 1.75rem;
  height: 1.75rem;
`;

const CommentText = styled.i`
  font-size: 1.4rem;
`;

const sorter = (a, b, key, ascending) => {
  const aa = ascending ? a : b;
  const bb = ascending ? b : a;

  if (!aa[key]) return -1;
  if (!bb[key]) return 1;
  return aa[key].localeCompare(bb[key]);
};

const makeTableHeaderCellProps = (key, { sortedColumnKey, ...props }) => ({
  sortable: true,
  sortKey: key,
  isSorted: sortedColumnKey === key,
  ...props,
});

const tableCells = [
  { id: 'timestamp', label: 'Date' },
  { id: 'triggeredBy', label: 'User' },
  { id: 'type', label: 'Action' },
];

const expandable = ['clientComment', 'FCGComment'];

const actionLabels = {
  approved: 'Approved',
  FCGComment: 'FCG comments',
  clientComment: 'Customer comments',
  templateUpload: 'Template uploaded',
};

const renderActionByType = ({ type, data }, onDownloadData) => {
  switch (type) {
    case 'validation':
      // TODO show errors in modal
      return data.text;
    case 'inputUploaded':
    case 'reportUploaded':
      return (
        <ExternalLink onClick={() => onDownloadData(data.fileId)}>
          File uploaded
          <StyledExternalIcon />
        </ExternalLink>
      );
    default:
      return actionLabels[type];
  }
};

export const EventsTableSection = ({
  events,
  isLoadingEvents,
  onUpdateEvents,
  onDownloadData,
}) => {
  const { sortedItems, ...sortProps } = useSortableTable({
    column: 'date',
    sorter,
    items: events,
  });

  const [expandedRows, setExpandedRows] = React.useState({});

  const handleExpandClick = React.useCallback(
    (id, expanded) => {
      setExpandedRows((current) => ({
        ...current,
        [id]: !expanded,
      }));
    },
    [setExpandedRows],
  );

  React.useEffect(() => {
    const expandedItems = sortedItems
      .filter(({ type }) => expandable.includes(type))
      .map(({ id }) => ({ [id]: true }));
    setExpandedRows(Object.assign({}, ...expandedItems));
  }, [sortedItems]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>Events</Card.Title>
        {onUpdateEvents && (
          <UpdateButtonContainer>
            <IconButton onClick={onUpdateEvents}>
              <RefreshIcon />
            </IconButton>
          </UpdateButtonContainer>
        )}
      </Card.Header>
      <Card.Body>
        <Table>
          <TableHead>
            <TableRow>
              {tableCells.map(({ id, label }) => (
                <TableCell
                  key={`${id}-header-cell`}
                  {...makeTableHeaderCellProps(id, sortProps)}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoadingEvents && (
            <TableBody>
              {sortedItems.length ? (
                sortedItems.map((item) => (
                  <React.Fragment key={item.id}>
                    <DefaultTableRow noBorder={expandedRows[item.id]}>
                      <>
                        {tableCells.map(({ id }) => (
                          <TableCell key={`${id}-data-cell`}>
                            {(() => {
                              switch (id) {
                                case 'timestamp':
                                  return item[id] ? formatDate(item[id]) : '';
                                case 'triggeredBy':
                                  return item[id];
                                case 'type':
                                  return renderActionByType(
                                    item,
                                    onDownloadData,
                                  );
                                default:
                                  return '<Unknown>';
                              }
                            })()}
                          </TableCell>
                        ))}
                        <TableCellRight>
                          {expandable.includes(item.type) && (
                            <IconButton
                              onClick={() =>
                                handleExpandClick(
                                  item.id,
                                  expandedRows[item.id],
                                )
                              }
                              size="small"
                            >
                              <ExpandIcon expanded={expandedRows[item.id]} />
                            </IconButton>
                          )}
                        </TableCellRight>
                      </>
                    </DefaultTableRow>
                    {expandedRows[item.id] && (
                      <DefaultTableRow>
                        <TableCell colSpan={4}>
                          <CommentText>{item.data.text}</CommentText>
                        </TableCell>
                      </DefaultTableRow>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <DefaultTableRow>
                  <TableCell colSpan={4}>
                    <EmptyTableState>No events found</EmptyTableState>
                  </TableCell>
                </DefaultTableRow>
              )}
            </TableBody>
          )}
        </Table>
        {isLoadingEvents && (
          <LoaderContainer>
            <Loader message="Loading events" size="small" disablePortal />
          </LoaderContainer>
        )}
      </Card.Body>
    </Card>
  );
};

EventsTableSection.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
  isLoadingEvents: PropTypes.bool,
  onUpdateEvents: PropTypes.func,
  onDownloadData: PropTypes.func,
};

EventsTableSection.defaultProps = {
  events: [],
  isLoadingEvents: false,
  onUpdateEvents: null,
  onDownloadData: null,
};
