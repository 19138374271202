import React from 'react';

import { DashboardPage } from './components';

// TODO: move to mock
// const data = {
//   messages: [
//     {
//       id: 4,
//       type: 'missing-input-file-upload',
//       actorId: 200,
//       message: '1 missing input file to upload - Portfolio',
//     },
//     {
//       id: 3,
//       type: 'pending-report-approval',
//       message: '3 reports to approve',
//     },
//     {
//       id: 1,
//       type: 'input-comment',
//       actorId: 100,
//       message: 'Comments on inputfile - Income statement 2019-09-30',
//     },
//     {
//       id: 2,
//       type: 'report-comment',
//       actorId: 100,
//       message: 'Comments on report - Likviditetstäckning 2019-09-30',
//     },
//   ],
// };

export const DashboardContainer = () => {
  return <DashboardPage messages={[]} />;
};
