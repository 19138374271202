import { stringify } from 'query-string';
import { ACCESS_TOKEN } from '../constants';

export const getAccessToken = () => {
  return window.sessionStorage.getItem(ACCESS_TOKEN);
};

// Authenticated fetch wrapper
const doFetchWithSession = async (token, ...args) => {
  const [url, options] = args;
  const headers = (options ? options.headers : {}) || {};
  headers.Authorization = `Bearer ${token}`;

  const actualOptions = {
    mode: 'cors',
    method: 'GET',
    ...(options || {}),
    headers,
    cors: true,
  };

  try {
    const res = await window.fetch(
      `${process.env.REACT_APP_API_PATH}${url}`,
      actualOptions,
    );
    return res;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
};

export const apiFetch = (...args) => {
  const token = getAccessToken();
  if (!token) {
    throw new Error('Not logged in');
  }
  return doFetchWithSession(token, ...args);
};

export const localFetch = async (url) => {
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  await sleep(Math.random() * (2000 - 500) + 500);
  return window.fetch(url);
};

export const checkAuthenticated = async () => {
  try {
    let res;
    if (process.env.REACT_APP_FEATURE_USE_MOCK === 'true') {
      res = await localFetch('/mock/init.json');
    } else {
      const token = getAccessToken();
      if (!token) {
        return false;
      }
      res = await apiFetch('/init');
    }
    if (!res.ok) {
      return Promise.reject(res);
    }
    return res.json();
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    // NOOP
  }
  return false;
};

export const startLoginFlow = (returnUrl = '/') => {
  const stateKey = Math.random().toString(36).substring(7);
  const state = { returnUrl };
  window.localStorage.setItem(stateKey, JSON.stringify(state));
  const parameters = {
    response_type: 'token',
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_AUTH_CALLBACK_URL,
    state: stateKey,
  };
  const loginUrl = `${process.env.REACT_APP_OAUTH_BASE_URL}/login?${stringify(
    parameters,
  )}&scope=${process.env.REACT_APP_OAUTH_SCOPES}`;
  window.location.href = loginUrl;
};

export const endLoginFlow = (accessToken) => {
  window.sessionStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const logout = () => {
  window.sessionStorage.clear();
  window.localStorage.clear();
  const parameters = {
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    logout_uri: process.env.REACT_APP_AUTH_LOGOUT_CALLBACK_URL,
  };
  const logoutUrl = `${process.env.REACT_APP_OAUTH_BASE_URL}/logout?${stringify(
    parameters,
  )}`;
  window.location = logoutUrl;
};
