import React from 'react';
import { useAsync, IfFulfilled, IfPending, IfRejected } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import { loadInputs } from '../../api';
import { AppContext } from '../../components/AppContext';
import { AccessControl } from '../../components/AccessControl';
import { FetchError } from '../../components/FetchError';
import { InputsOverviewPage } from './components';

export const InputsOverviewContainer = () => {
  const { permissions } = React.useContext(AppContext);

  const inputsReq = useAsync({ promiseFn: loadInputs });

  return (
    <AccessControl
      permissions={permissions}
      requiredPermissions={['reportiq:InputList']}
      renderNoAccess={() => (
        <FetchError message="You don't currently have permission to list the inputs." />
      )}
    >
      <IfPending state={inputsReq}>
        <Loader message="Loading inputs" />;
      </IfPending>
      <IfRejected state={inputsReq}>
        {(error) => (
          <FetchError
            message={`Failed to fetch inputs from server. ${error.message} `}
          />
        )}
      </IfRejected>
      <IfFulfilled state={inputsReq}>
        {(data) => <InputsOverviewPage inputs={data.result} />}
      </IfFulfilled>
    </AccessControl>
  );
};
