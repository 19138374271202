import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  SideNavigation,
  Header,
  childrenPropType,
} from '@fcg-tech/regtech-components';

import {
  ROUTE_INPUTS_OVERVIEW,
  ROUTE_DASHBOARD,
  ROUTE_REPORTS_OVERVIEW,
  ROUTE_CLIENTS_OVERVIEW,
  ROUTE_CALENDAR,
} from '../../routes';
import logo from '../../images/reportiq.png';
import { logout } from '../../api/auth';
import { AppContext } from '../AppContext';

const Container = styled.div`
  @media screen {
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  @media print {
    display: block;
  }
`;

const Content = styled.div`
  ${({ theme }) => css`
    @media screen {
      background-color: ${theme.colors.background.default};
      display: flex;
      flex: 1;
      justify-content: stretch;
      max-height: 100vh;
    }

    @media print {
      background-color: ${theme.colors.common.white};
      display: block;
    }
  `}
`;

const PageContainer = styled.div`
  @media screen {
    flex: 1;
    overflow-y: auto;
    max-height: calc(100vh - 4.8rem);
  }

  @media print {
    display: block;
  }
`;

const LogoContainer = styled.div`
  font-size: 4rem;
  font-family: Montserrat;
  letter-spacing: -5px;
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 4rem 0;
`;

const Logo = styled.img`
  width: 12rem;
  height: 12rem;
`;

const navItemsWithPermission = [
  {
    label: 'Inputs Overview',
    url: ROUTE_INPUTS_OVERVIEW,
    permission: 'reportiq:InputList',
  },
  {
    label: 'Reports Overview',
    url: ROUTE_REPORTS_OVERVIEW,
    permission: 'reportiq:ReportList',
  },
  {
    label: 'Clients Overview',
    url: ROUTE_CLIENTS_OVERVIEW,
    permission: 'reportiq:ClientList',
  },
];

export const MainLayout = ({ children, isLoggedIn }) => {
  const { permissions } = React.useContext(AppContext);

  const [isSideNavigationOpen, setIsSideNavigationOpen] = React.useState(true);
  const handleToggleSideNavigation = React.useCallback(
    () => setIsSideNavigationOpen((prev) => !prev),
    [setIsSideNavigationOpen],
  );

  const handleLogout = React.useCallback(() => {
    if (process.env.REACT_APP_FEATURE_USE_MOCK === 'true') {
      return;
    }
    logout();
  }, []);

  const navItems = React.useMemo(() => {
    return [
      { label: 'Dashboard', url: ROUTE_DASHBOARD, exact: true },
    ].concat(
      navItemsWithPermission
        .map(
          ({ label, url, permission }) =>
            permissions?.includes(permission) && {
              label,
              url,
            },
        )
        .filter(Boolean),
    );
  }, [permissions]);

  return (
    <Container>
      <Header
        onLogout={handleLogout}
        isLoggedIn={isLoggedIn}
        onToggleMenu={handleToggleSideNavigation}
      />
      <Content>
        <SideNavigation isOpen={isSideNavigationOpen} navItems={navItems}>
          <LogoContainer>
            <Logo src={logo} alt="ReportiQ" />
          </LogoContainer>
        </SideNavigation>
        <PageContainer>{children}</PageContainer>
      </Content>
    </Container>
  );
};

MainLayout.propTypes = {
  children: childrenPropType.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};
