import React from 'react';
import { useAsync, IfFulfilled, IfPending, IfRejected } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import { loadReports } from '../../api';
import { AppContext } from '../../components/AppContext';
import { AccessControl } from '../../components/AccessControl';
import { FetchError } from '../../components/FetchError';
import { ReportsOverviewPage } from './components';

export const ReportsOverviewContainer = () => {
  const { permissions } = React.useContext(AppContext);

  const reportsReq = useAsync({ promiseFn: loadReports });

  return (
    <AccessControl
      permissions={permissions}
      requiredPermissions={['reportiq:ReportList']}
      renderNoAccess={() => (
        <FetchError message="You don't currently have permission to list the reports." />
      )}
    >
      <IfPending state={reportsReq}>
        <Loader message="Loading reports" />
      </IfPending>
      <IfRejected state={reportsReq}>
        {(error) => (
          <FetchError
            message={`Failed to fetch reports from server. ${error.message}`}
          />
        )}
      </IfRejected>
      <IfFulfilled state={reportsReq}>
        {(data) => <ReportsOverviewPage reports={data.result} />}
      </IfFulfilled>
    </AccessControl>
  );
};
