import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { AppContext } from '../../../components/AppContext';
import { ROUTE_NEW_CLIENT } from '../../../routes';
import { AccessControl } from '../../../components/AccessControl';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { ClientsTable } from './ClientsTable';

export const ClientsOverviewPage = ({ clients }) => {
  const { permissions } = React.useContext(AppContext);

  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(() => updateBreadcrumbs(['Clients overview']), [
    updateBreadcrumbs,
  ]);

  return (
    <PageLayout>
      <PageHeader title="Clients overview" breadcrumbs={breadcrumbs}>
        <AccessControl
          permissions={permissions}
          requiredPermissions={['reportiq:ClientAdd']}
        >
          <Button to={ROUTE_NEW_CLIENT}>Add client</Button>
        </AccessControl>
      </PageHeader>
      <PageLayout.PaddedContent>
        <Card>
          <Card.Header>
            <Card.Title>Clients</Card.Title>
          </Card.Header>
          <Card.Body>
            <ErrorBoundary>
              <ClientsTable clients={clients} />
            </ErrorBoundary>
          </Card.Body>
        </Card>
      </PageLayout.PaddedContent>
    </PageLayout>
  );
};

ClientsOverviewPage.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
        internalReference: PropTypes.string,
      }),
    }),
  ),
};

ClientsOverviewPage.defaultProps = {
  clients: [],
};
