import React from 'react';
import PropTypes from 'prop-types';
import { ErrorModal } from '../Modals';

export const FetchError = ({ message }) => {
  const [showError, setShowError] = React.useState(true);

  const handleClose = React.useCallback(() => {
    setShowError(false);
  }, [setShowError]);

  if (!showError) {
    return null;
  }

  return <ErrorModal message={message} onRequestClose={handleClose} />;
};

FetchError.propTypes = {
  message: PropTypes.string,
};

FetchError.defaultProps = {
  message: '',
};
