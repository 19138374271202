import { format } from 'date-fns';

import tooltips from './tooltips.json';
import { DATE_FMT } from './constants';

export const formatDate = (date) => {
  if (typeof date === 'string') {
    return format(new Date(date), DATE_FMT);
  }
  return format(date, DATE_FMT);
};

export const randId = () => Math.random().toString(32).substring(2);

export const getTooltip = (id) => tooltips[id] || '';
