export const DATE_FMT = 'yyyy-MM-dd';

export const ACCESS_TOKEN = 'ACCESS_TOKEN';

export const FREQUENCY_OPTIONS = [
  { key: 'monthly', label: 'Monthly' },
  { key: 'quarterly', label: 'Quarterly' },
  { key: 'semiAnually', label: 'Semianually' },
  { key: 'annually', label: 'Annually' },
];

export const FREQUENCY_ITEMS = {
  oneOff: 'Once only',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiAnually: 'Semianually',
  annually: 'Annually',
};

export const INPUT_SKELETON = {
  data: {
    name: '',
    recurring: '',
    firstDueDate: '',
    status: '',
    template: {},
    client: {},
  },
};

export const INPUT_FORM_DATA = {
  data: {
    name: '',
    recurring: false,
    frequency: '',
    firstDueDate: '',
    client: {},
    files: null,
  },
};

export const REPORT_SKELETON = {
  data: {
    type: '',
    client: {},
    recurring: '',
    referencePeriodEnd: '',
    firstDueDate: '',
    status: '',
  },
};

export const REPORT_FORM_DATA = {
  data: {
    type: '',
    client: {},
    recurring: false,
    frequency: '',
    referencePeriodEnd: '',
    firstDueDate: '',
    status: '',
  },
};

export const CLIENT_SKELETON = {
  data: {
    name: '',
    internalReference: '',
    members: [],
  },
};
