/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  ChatBubbleIcon,
  DownloadIcon,
  OkIcon,
  PageHeader,
  PageLayout,
  UploadIcon,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { FREQUENCY_ITEMS } from '../../../constants';
import { ROUTE_INPUTS_OVERVIEW } from '../../../routes';
import { formatDate } from '../../../utils';
import { inputShape } from '../../../propTypes';
import {
  checkPermissions,
  AccessControl,
} from '../../../components/AccessControl';
import { CommentModal } from '../../../components/Modals';
import { EditActionGroup } from '../../../components/ActionGroups';
import { EventsTableSection } from '../../../components/PageSections';

const Details = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
`;

const Detail = styled.li`
  margin: 0 0 1.4rem 0;
  font-size: 1.4rem;
`;

const DetailName = styled.span`
  font-weight: 600;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
  & > * + * {
    margin-left: 1rem;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

export const InputDetailsPage = ({
  input,
  events,
  isLoadingEvents,
  onEdit,
  onDelete,
  onApprove,
  onAddComment,
  onUpdateEvents,
  onUploadData,
  onDownloadData,
  onDownloadTemplate,
}) => {
  const [showCommentModal, setShowCommentModal] = React.useState(false);
  const menuItems = [{ id: 'delete', label: 'Delete', callback: onDelete }];

  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(() => {
    updateBreadcrumbs([
      { label: 'Inputs overview', url: ROUTE_INPUTS_OVERVIEW },
      input.data.name,
    ]);
  }, [updateBreadcrumbs, input.data.name]);

  const handleOpenCommentModal = React.useCallback(() => {
    setShowCommentModal(true);
  }, [setShowCommentModal]);

  const handleCommentConfirm = React.useCallback(
    (comment) => {
      if (comment.text) {
        onAddComment(comment);
        setShowCommentModal(false);
      }
    },
    [onAddComment, setShowCommentModal],
  );

  const handleCommentCancel = React.useCallback(() => {
    setShowCommentModal(false);
  }, [setShowCommentModal]);

  const handleUploadData = React.useCallback(
    (event) => {
      if (onUploadData) {
        onUploadData(event.target.files);
      }
    },
    [onUploadData],
  );

  return (
    <>
      <PageLayout>
        <PageHeader title={input.data.name} breadcrumbs={breadcrumbs}>
          <EditActionGroup
            menuItems={
              checkPermissions(input.permissions, ['reportiq:InputDelete'])
                ? menuItems
                : []
            }
            canEdit={checkPermissions(input.permissions, [
              'reportiq:InputEdit',
            ])}
            onEdit={onEdit}
          />
        </PageHeader>
        <PageLayout.PaddedContent>
          <Details>
            <Detail>
              <DetailName>Client:</DetailName> {input.data.client.name}
            </Detail>
            <Detail>
              <DetailName>Due date:</DetailName>{' '}
              {formatDate(input.data.firstDueDate)}
            </Detail>
            <Detail>
              <DetailName>Recurring:</DetailName>{' '}
              {FREQUENCY_ITEMS[input.data.recurring]}
            </Detail>
            <Detail>
              <DetailName>Template:</DetailName>{' '}
              {input.data?.template?.name || 'No template uploaded'}
            </Detail>
          </Details>
          <ButtonRow>
            <AccessControl
              permissions={input.permissions}
              requiredPermissions={['reportiq:InputGetTemplate']}
            >
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                icon={<DownloadIcon />}
                onClick={onDownloadTemplate}
                disabled={!input.data?.template?.id}
              >
                Download template
              </Button>
            </AccessControl>
            <AccessControl
              permissions={input.permissions}
              requiredPermissions={['reportiq:InputAddFile']}
            >
              <label htmlFor="upload-input-data">
                <Button
                  as="span"
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  icon={<UploadIcon />}
                >
                  Upload data
                </Button>
              </label>
              <HiddenInput
                id="upload-input-data"
                onChange={handleUploadData}
                type="file"
              />
            </AccessControl>
            <AccessControl
              permissions={input.permissions}
              requiredPermissions={['reportiq:InputAddComment']}
            >
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                icon={<ChatBubbleIcon />}
                onClick={handleOpenCommentModal}
              >
                Comment
              </Button>
            </AccessControl>
            <AccessControl
              permissions={input.permissions}
              requiredPermissions={['reportiq:InputApprove']}
            >
              <Button
                disabled={input.data.status === 'approved'}
                size="medium"
                icon={<OkIcon />}
                onClick={onApprove}
              >
                Approve
              </Button>
            </AccessControl>
          </ButtonRow>
          <EventsTableSection
            events={events}
            isLoadingEvents={isLoadingEvents}
            onUpdateEvents={onUpdateEvents}
            onDownloadData={onDownloadData}
          />
        </PageLayout.PaddedContent>
      </PageLayout>
      {showCommentModal && (
        <CommentModal
          onConfirm={handleCommentConfirm}
          onCancel={handleCommentCancel}
        />
      )}
    </>
  );
};

InputDetailsPage.propTypes = {
  input: inputShape,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  isLoadingEvents: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAddComment: PropTypes.func,
  onApprove: PropTypes.func,
  onUpdateEvents: PropTypes.func,
  onUploadData: PropTypes.func,
  onDownloadData: PropTypes.func,
  onDownloadTemplate: PropTypes.func,
};

InputDetailsPage.defaultProps = {
  input: null,
  events: [],
  isLoadingEvents: false,
  onEdit: null,
  onDelete: null,
  onAddComment: null,
  onApprove: null,
  onUpdateEvents: null,
  onUploadData: null,
  onDownloadData: null,
  onDownloadTemplate: null,
};
