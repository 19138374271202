import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { ROUTE_INPUTS_OVERVIEW } from '../../../routes';
import { inputShape } from '../../../propTypes';
import { EditContext } from '../../../components/EditContext';
import { EditInputSection } from '../../../components/PageSections';
import { SaveActionGroup } from '../../../components/ActionGroups';

export const EditInputDetailsPage = ({
  input,
  clients,
  isSaving,
  onSave,
  onCancel,
}) => {
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(() => {
    updateBreadcrumbs([
      { label: 'Inputs overview', url: ROUTE_INPUTS_OVERVIEW },
      input.data.name,
    ]);
  }, [updateBreadcrumbs, input.data.name]);

  const inputFormData = React.useMemo(
    () => ({
      data: {
        ...input.data,
        recurring: input.data.recurring !== 'oneOff',
        frequency:
          input.data.recurring !== 'oneOff' ? input.data.recurring : '',
        files: null,
      },
    }),
    [input],
  );

  return (
    <EditContext.Provider value>
      <Formik initialValues={inputFormData} onSubmit={onSave}>
        {({ values, handleSubmit }) => (
          <Form>
            <PageLayout>
              <PageHeader
                title={inputFormData.data.name}
                breadcrumbs={breadcrumbs}
              >
                <SaveActionGroup
                  isSaving={isSaving}
                  onSave={handleSubmit}
                  onCancel={onCancel}
                />
              </PageHeader>
              <PageLayout.PaddedContent>
                <EditInputSection input={values} clients={clients} />
              </PageLayout.PaddedContent>
            </PageLayout>
          </Form>
        )}
      </Formik>
    </EditContext.Provider>
  );
};

EditInputDetailsPage.propTypes = {
  input: inputShape,
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditInputDetailsPage.defaultProps = {
  input: null,
  clients: [],
  isSaving: false,
  onSave: null,
  onCancel: null,
};
