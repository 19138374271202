import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Button, Modal as DefaultModal } from '@fcg-tech/regtech-components';
import {
  BooleanDecision,
  Label,
  TextField,
} from '@fcg-tech/regtech-formik-components';

import { Field, FormGroup, FormSection } from '../Form';

const Modal = styled(DefaultModal)`
  min-width: 40vw;
  max-width: 80rem;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AddMemberModal = ({ member, onSubmit, onCancel }) => (
  <Formik initialValues={member} onSubmit={onSubmit}>
    {({ handleSubmit }) => (
      <Modal>
        <Modal.Body>
          <Modal.Title>
            {member.email ? 'Edit member' : 'Add member'}
          </Modal.Title>
          <FormSection>
            <FormSection.Column>
              <FormGroup>
                <Label column htmlFor="add-member-email">
                  Email
                </Label>
                <Field
                  component={TextField}
                  readOnly={Boolean(member.email)}
                  id="add-member-email"
                  name="email"
                  placeholder="Enter member email"
                  type="text"
                />
              </FormGroup>
              <FormGroup>
                <BooleanDecision.FieldContainer>
                  <BooleanDecision.FieldLabel>
                    External user?
                  </BooleanDecision.FieldLabel>
                  <Field component={BooleanDecision} name="isExternal" />
                </BooleanDecision.FieldContainer>
              </FormGroup>
            </FormSection.Column>
          </FormSection>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={handleSubmit}>Confirm</Button>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
);

AddMemberModal.propTypes = {
  member: PropTypes.shape({
    email: PropTypes.string,
    isExternal: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

AddMemberModal.defaultProps = {
  member: {},
  onSubmit: null,
  onCancel: null,
};
