import React from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { useAsync, IfRejected } from 'react-async';

import {
  constructRoute,
  ROUTE_CLIENTS_OVERVIEW,
  ROUTE_CLIENT_DETAILS,
} from '../../routes';
import { createClient } from '../../api';
import { AppContext } from '../../components/AppContext';
import { AccessControl } from '../../components/AccessControl';
import { FetchError } from '../../components/FetchError';
import { NewClientPage } from './components';

export const NewClientContainer = () => {
  const history = useHistory();
  const { permissions } = React.useContext(AppContext);
  const [isSaving, setIsSaving] = React.useState(false);

  // proxy fns
  const saveClientProxy = async (args) => {
    setIsSaving(true);
    try {
      const { Location } = await createClient(...args);
      if (Location) {
        const match = matchPath(Location, { path: '/clients/:clientId' });
        const { clientId } = match.params;
        history.push(constructRoute(ROUTE_CLIENT_DETAILS, { clientId }));
      } else {
        setIsSaving(false);
      }
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
  };

  // defer fns
  const saveReq = useAsync({ deferFn: saveClientProxy });

  // callbacks
  const handleSave = React.useCallback(
    (client) => {
      saveReq.run({ client: client.data });
    },
    [saveReq],
  );

  const handleCancel = React.useCallback(() => {
    history.push(ROUTE_CLIENTS_OVERVIEW);
  }, [history]);

  return (
    <AccessControl
      permissions={permissions}
      requiredPermissions={['reportiq:ClientAdd']}
      renderNoAccess={() => (
        <FetchError message="You don't have permission to create new clients." />
      )}
    >
      <IfRejected state={saveReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <NewClientPage
        isSaving={isSaving}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </AccessControl>
  );
};
