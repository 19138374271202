import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { INPUT_FORM_DATA } from '../../../constants';
import { ROUTE_INPUTS_OVERVIEW } from '../../../routes';
import { EditContext } from '../../../components/EditContext';
import { EditInputSection } from '../../../components/PageSections';
import { SaveActionGroup } from '../../../components/ActionGroups';

export const NewInputPage = ({ clients, isSaving, onSave, onCancel }) => {
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(
    () =>
      updateBreadcrumbs([
        { label: 'Inputs overview', url: ROUTE_INPUTS_OVERVIEW },
        'Create input',
      ]),
    [updateBreadcrumbs],
  );

  const input = React.useMemo(() => ({ ...INPUT_FORM_DATA }), []);

  return (
    <EditContext.Provider value>
      <Formik initialValues={input} onSubmit={onSave}>
        {({ handleSubmit, errors, values }) => (
          <Form>
            <PageLayout>
              <PageHeader title="Create input" breadcrumbs={breadcrumbs}>
                <SaveActionGroup
                  isSaving={isSaving}
                  onSave={handleSubmit}
                  onCancel={onCancel}
                />
              </PageHeader>
              <PageLayout.PaddedContent>
                <EditInputSection
                  input={values}
                  clients={clients}
                  errors={errors}
                />
              </PageLayout.PaddedContent>
            </PageLayout>
          </Form>
        )}
      </Formik>
    </EditContext.Provider>
  );
};

NewInputPage.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

NewInputPage.defaultProps = {
  clients: [],
  isSaving: false,
  onSave: null,
  onCancel: null,
};
