import React from 'react';
import styled, { css } from 'styled-components';
import { childrenPropType } from '@fcg-tech/regtech-formik-components';

import { FormGroup } from './FormGroup';

export const FormSection = styled.div`
  ${({ theme }) => css`
    margin: 2.4rem 0;
    display: flex;
    width: 100%;

    & + &,
    & + ${FormGroup} {
      margin-top: 2.4rem;
    }

    @media screen and (max-width: ${theme.breakpoints.mobile}px) {
      flex-direction: column;
    }
  `}
`;

const ColumnContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    flex: 1;

    padding: 0 2rem 0 1rem;

    & + & {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 1rem;
    }

    &:empty {
      border-left: 0 !important;
    }

    @media screen and (max-width: ${theme.breakpoints.mobile}px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`;

const FormSectionColumn = ({ children, ...props }) => (
  <ColumnContainer {...props}>{children}</ColumnContainer>
);

FormSectionColumn.propTypes = {
  children: childrenPropType,
};

FormSectionColumn.defaultProps = {
  children: null,
};

FormSection.Column = FormSectionColumn;
