import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Card,
  IconButton,
  EditIcon,
  TrashIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@fcg-tech/regtech-components';

import { AddMemberModal } from '../Modals';
import { EditContext } from '../EditContext';

const TableCellRight = styled(TableCell)`
  justify-content: flex-end;
`;

const EmptyTableState = styled.div`
  flex: 1;
  text-align: center;
`;

const AddButtonContainer = styled.div`
  margin-left: auto;
`;

const roleMap = {
  true: 'External',
  false: 'Internal',
};

const MEMBERS_FIELD = 'data.members';

export const MembersTableSection = ({ members, setFieldValue }) => {
  const isEditEnabled = React.useContext(EditContext);

  const [editMember, setEditMember] = React.useState(null);

  const handleCancel = React.useCallback(() => {
    setEditMember(null);
  }, [setEditMember]);

  const handleConfirm = React.useCallback(
    ({ email, isExternal }) => {
      setEditMember(null);
      const index = members.findIndex((member) => member.email === email);
      if (index >= 0) {
        setFieldValue(MEMBERS_FIELD, [
          ...members.slice(0, index),
          { email, isExternal },
          ...members.slice(index + 1),
        ]);
      } else {
        setFieldValue(MEMBERS_FIELD, [...members, { email, isExternal }]);
      }
    },
    [members, setFieldValue, setEditMember],
  );

  const handleDelete = React.useCallback(
    (email) => {
      const index = members.findIndex((member) => member.email === email);
      if (index >= 0) {
        setFieldValue(MEMBERS_FIELD, [
          ...members.slice(0, index),
          ...members.slice(index + 1),
        ]);
      }
    },
    [members, setFieldValue],
  );

  const handleAdd = React.useCallback(() => {
    setEditMember({ email: '', isExternal: true });
  }, [setEditMember]);

  const handleEdit = React.useCallback(
    (event, email) => {
      event.preventDefault();
      event.stopPropagation();
      const member = members.find((m) => m.email === email);
      setEditMember(member);
    },
    [members, setEditMember],
  );

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Members</Card.Title>
          {isEditEnabled && (
            <AddButtonContainer>
              <Button onClick={handleAdd}>Add member</Button>
            </AddButtonContainer>
          )}
        </Card.Header>
        <Card.Body>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {members.length ? (
                members.map(({ email, isExternal }) => (
                  <TableRow key={email}>
                    <TableCell>{email}</TableCell>
                    <TableCell>{roleMap[isExternal]}</TableCell>
                    {isEditEnabled ? (
                      <TableCellRight>
                        <IconButton
                          size="small"
                          onClick={(event) => handleEdit(event, email)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(email)}
                        >
                          <TrashIcon />
                        </IconButton>
                      </TableCellRight>
                    ) : (
                      <TableCell />
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <EmptyTableState>No members added</EmptyTableState>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card.Body>
      </Card>
      {editMember && (
        <AddMemberModal
          member={editMember}
          onSubmit={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

MembersTableSection.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      isExternal: PropTypes.bool,
    }),
  ),
  setFieldValue: PropTypes.func.isRequired,
};

MembersTableSection.defaultProps = {
  members: [],
};
