import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, ErrorIcon, Modal } from '@fcg-tech/regtech-components';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem 3rem 2rem;
  min-width: 40vw;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  ${({ theme }) => css`
    width: 4rem;
    height: 4rem;
    color: ${theme.colors.warning.main};
    margin-bottom: 1rem;
  `}
`;

const Message = styled.p`
  margin: 2rem 2rem 3rem 2rem;
  font-size: 1.6rem;
  font-weight: 400;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ConfirmModal = ({ message, onConfirm, onCancel }) => (
  <Modal onHide={onCancel}>
    <Content>
      <StyledErrorIcon />
      <Message>{message}</Message>
      <ButtonRow>
        <Button onClick={onConfirm}>Yes</Button>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonRow>
    </Content>
  </Modal>
);

ConfirmModal.propTypes = {
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

ConfirmModal.defaultProps = {
  message: null,
  onConfirm: null,
  onCancel: null,
};
