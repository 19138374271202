import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync, IfFulfilled, IfPending, IfRejected } from 'react-async';
import { Loader } from '@fcg-tech/regtech-components';

import { ROUTE_CLIENTS_OVERVIEW } from '../../routes';
import { deleteClient, loadClient, updateClient } from '../../api';
import { ConfirmModal } from '../../components/Modals';
import { FetchError } from '../../components/FetchError';
import { ClientDetailsPage } from './components';

export const ClientDetailsContainer = () => {
  const history = useHistory();
  const { clientId } = useParams();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  // promise fns
  const clientReq = useAsync({ promiseFn: loadClient, clientId });

  // proxy fns
  const deleteProxy = async (args) => {
    try {
      await deleteClient(...args);
    } catch (err) {
      throw err;
    }
    history.push(ROUTE_CLIENTS_OVERVIEW);
  };

  const updateClientProxy = async (args) => {
    setIsSaving(true);
    try {
      await updateClient(...args);
      clientReq.reload();
      setIsEditEnabled(false);
    } catch (err) {
      setIsSaving(false);
      throw err;
    }
    setIsSaving(false);
  };

  // defer fns
  const deleteReq = useAsync({ deferFn: deleteProxy });
  const saveReq = useAsync({ deferFn: updateClientProxy });

  // callbacks
  const handleEdit = React.useCallback(() => {
    setIsEditEnabled(true);
  }, [setIsEditEnabled]);

  const handleCancel = React.useCallback(() => {
    setIsEditEnabled(false);
  }, [setIsEditEnabled]);

  const handleSave = React.useCallback(
    (client) => {
      saveReq.run({ clientId, client: client.data });
    },
    [saveReq, clientId],
  );

  const handleDelete = React.useCallback(() => {
    setShowDeleteConfirm(true);
  }, [setShowDeleteConfirm]);

  const handleDeleteCancel = React.useCallback(() => {
    setShowDeleteConfirm(false);
  }, [setShowDeleteConfirm]);

  const handleDeleteConfirm = React.useCallback(() => {
    deleteReq.run({ clientId });
    setShowDeleteConfirm(false);
  }, [clientId, deleteReq, setShowDeleteConfirm]);

  return (
    <>
      <IfPending state={clientReq}>
        <Loader message="Loading details" />
      </IfPending>
      <IfRejected state={clientReq}>
        {(error) => (
          <FetchError
            message={`Failed to fetch client data from server. ${error.message}`}
          />
        )}
      </IfRejected>
      <IfRejected state={saveReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfRejected state={deleteReq}>
        {(error) => <FetchError message={error.message} />}
      </IfRejected>
      <IfFulfilled state={clientReq}>
        {(data) => (
          <ClientDetailsPage
            client={data}
            isEditEnabled={isEditEnabled}
            isSaving={isSaving}
            onSave={handleSave}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onCancel={handleCancel}
          />
        )}
      </IfFulfilled>
      {showDeleteConfirm && (
        <ConfirmModal
          message="Are you sure you wish to delete this client?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </>
  );
};
