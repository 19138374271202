import React from 'react';
import { useAsync } from 'react-async';
import {
  BreadcrumbsContextProvider,
  Loader,
} from '@fcg-tech/regtech-components';

import { ROUTE_DASHBOARD } from './routes';
import { checkAuthenticated, startLoginFlow } from './api/auth';
import { AppContext } from './components/AppContext';
import { Routes } from './components/Routes';

export const App = () => {
  const { data, isLoading, error } = useAsync({
    promiseFn: checkAuthenticated,
  });

  if (isLoading) {
    return <Loader message="Check authentication status" />;
  }

  if (!data || error) {
    startLoginFlow(window.location.pathname);
    return null;
  }

  return (
    <AppContext.Provider value={data}>
      <BreadcrumbsContextProvider
        defaultLabel="Home"
        defaultUrl={ROUTE_DASHBOARD}
      >
        <Routes />
      </BreadcrumbsContextProvider>
    </AppContext.Provider>
  );
};
