import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFoundPage, childrenPropType } from '@fcg-tech/regtech-components';

import {
  ROUTE_CLIENT_DETAILS,
  ROUTE_CLIENTS_OVERVIEW,
  ROUTE_DASHBOARD,
  ROUTE_INPUT_DETAILS,
  ROUTE_INPUTS_OVERVIEW,
  ROUTE_NEW_CLIENT,
  ROUTE_NEW_INPUT,
  ROUTE_NEW_REPORT,
  ROUTE_REPORT_DETAILS,
  ROUTE_REPORTS_OVERVIEW,
  ROUTE_CALENDAR,
} from '../../routes';
import {
  CalendarContainer,
  ClientDetailsContainer,
  ClientsOverviewContainer,
  DashboardContainer,
  InputDetailsContainer,
  InputsOverviewContainer,
  NewClientContainer,
  NewInputContainer,
  NewReportContainer,
  ReportDetailsContainer,
  ReportsOverviewContainer,
} from '../../containers';
import { MainLayout } from '../Layouts';

const LayoutRoute = ({ children, ...props }) => (
  <Route {...props}>
    <MainLayout isLoggedIn>{children}</MainLayout>
  </Route>
);

LayoutRoute.propTypes = {
  children: childrenPropType.isRequired,
};

export const Routes = () => (
  <Switch>
    <LayoutRoute exact path={ROUTE_DASHBOARD}>
      <DashboardContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_CALENDAR}>
      <CalendarContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_INPUTS_OVERVIEW}>
      <InputsOverviewContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_NEW_INPUT}>
      <NewInputContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_INPUT_DETAILS}>
      <InputDetailsContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_REPORTS_OVERVIEW}>
      <ReportsOverviewContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_NEW_REPORT}>
      <NewReportContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_REPORT_DETAILS}>
      <ReportDetailsContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_CLIENTS_OVERVIEW}>
      <ClientsOverviewContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_NEW_CLIENT}>
      <NewClientContainer />
    </LayoutRoute>
    <LayoutRoute exact path={ROUTE_CLIENT_DETAILS}>
      <ClientDetailsContainer />
    </LayoutRoute>

    <Route path="*">
      <NotFoundPage />
    </Route>
  </Switch>
);
