import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  PageHeader,
  PageLayout,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { ROUTE_REPORTS_OVERVIEW } from '../../../routes';
import { reportShape } from '../../../propTypes';
import { EditReportSection } from '../../../components/PageSections';
import { EditContext } from '../../../components/EditContext';
import { SaveActionGroup } from '../../../components/ActionGroups';

export const EditReportDetailsPage = ({
  report,
  clients,
  isSaving,
  onSave,
  onCancel,
}) => {
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(
    () =>
      updateBreadcrumbs([
        { label: 'Reports overview', url: ROUTE_REPORTS_OVERVIEW },
        report.data.type,
      ]),
    [updateBreadcrumbs, report.data.type],
  );

  const reportFormData = React.useMemo(
    () => ({
      data: {
        ...report.data,
        recurring: report.data.recurring !== 'oneOff',
        frequency:
          report.data.recurring !== 'oneOff' ? report.data.recurring : '',
      },
    }),
    [report],
  );

  return (
    <EditContext.Provider value>
      <Formik initialValues={reportFormData} onSubmit={onSave}>
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <PageLayout>
              <PageHeader
                title={reportFormData.data.type}
                breadcrumbs={breadcrumbs}
              >
                <SaveActionGroup
                  isSaving={isSaving}
                  onSave={handleSubmit}
                  onCancel={onCancel}
                />
              </PageHeader>
              <PageLayout.PaddedContent>
                <EditReportSection report={values} clients={clients} />
              </PageLayout.PaddedContent>
            </PageLayout>
          </form>
        )}
      </Formik>
    </EditContext.Provider>
  );
};

EditReportDetailsPage.propTypes = {
  report: reportShape,
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditReportDetailsPage.defaultProps = {
  report: null,
  clients: [],
  isSaving: false,
  onSave: null,
  onCancel: null,
};
