export const ROUTE_DASHBOARD = '/';
export const ROUTE_CALENDAR = '/calendar';
export const ROUTE_INPUTS_OVERVIEW = '/inputs';
export const ROUTE_INPUT_DETAILS = '/inputs/:inputId';
export const ROUTE_NEW_INPUT = '/inputs/new';
export const ROUTE_REPORTS_OVERVIEW = '/reports';
export const ROUTE_REPORT_DETAILS = '/reports/:reportId';
export const ROUTE_NEW_REPORT = '/reports/new';
export const ROUTE_CLIENTS_OVERVIEW = '/clients';
export const ROUTE_CLIENT_DETAILS = '/clients/:clientId';
export const ROUTE_NEW_CLIENT = '/clients/new';

export const constructRoute = (route, args) =>
  Object.entries(args)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => Boolean(value))
    .reduce(
      (current, [key, value]) => current.replace(`:${key}`, value),
      route,
    );
