import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Card,
  PageHeader,
  PageLayout,
  SearchTextField,
  useBreadcrumbs,
} from '@fcg-tech/regtech-components';

import { ROUTE_NEW_REPORT } from '../../../routes';
import { formatDate } from '../../../utils';
import { useSearch } from '../../../hooks';
import { reportShape } from '../../../propTypes';
import { AppContext } from '../../../components/AppContext';
import { AccessControl } from '../../../components/AccessControl';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { ReportsTable } from './ReportsTable';

const SearchContainer = styled.div`
  flex: 1;
  max-width: 34rem;
  margin-left: auto;
`;

const searchFn = (item, searchTerm) => {
  const flattenItem = { ...item.metadata, ...item.data };
  // TODO: should we only filter on displayed keys in table?
  const keys = Object.keys(flattenItem);

  const match = keys.some((key) => {
    switch (key) {
      case 'client':
        return flattenItem[key].name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      case 'modified':
      case 'firstDueDate':
      case 'referencePeriodEnd':
        return formatDate(flattenItem[key])
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      default:
        return flattenItem[key]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
    }
  });

  return match ? item : null;
};

export const ReportsOverviewPage = ({ reports }) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [filteredReports, onFilterChange] = useSearch(reports, searchFn);

  const { permissions } = React.useContext(AppContext);

  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  React.useEffect(() => updateBreadcrumbs(['Reports overview']), [
    updateBreadcrumbs,
  ]);

  const handleSearch = React.useCallback(
    (event) => {
      setSearchValue(event.target.value);
      onFilterChange(event);
    },
    [setSearchValue, onFilterChange],
  );

  return (
    <PageLayout>
      <PageHeader title="Reports overview" breadcrumbs={breadcrumbs}>
        <AccessControl
          permissions={permissions}
          requiredPermissions={['reportiq:ReportAdd']}
        >
          <Button to={ROUTE_NEW_REPORT}>Add report</Button>
        </AccessControl>
      </PageHeader>
      <PageLayout.PaddedContent>
        <Card>
          <Card.Header>
            <Card.Title>Reports</Card.Title>
            <SearchContainer>
              <SearchTextField
                placeholder="Search..."
                onChange={handleSearch}
                value={searchValue}
              />
            </SearchContainer>
          </Card.Header>
          <Card.Body>
            <ErrorBoundary>
              <ReportsTable reports={filteredReports} />
            </ErrorBoundary>
          </Card.Body>
        </Card>
      </PageLayout.PaddedContent>
    </PageLayout>
  );
};

ReportsOverviewPage.propTypes = {
  reports: PropTypes.arrayOf(reportShape),
};

ReportsOverviewPage.defaultProps = {
  reports: [],
};
