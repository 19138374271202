import React from 'react';

export const useSearch = (items, searchFn) => {
  const [filteredItems, setFilteredItems] = React.useState(items);

  const onFilterChange = React.useCallback(
    (event) => {
      const searchTerm = event.target.value;
      setFilteredItems(items.filter((item) => searchFn?.(item, searchTerm)));
    },
    [items, setFilteredItems, searchFn],
  );

  return [filteredItems, onFilterChange];
};
