import PropTypes from 'prop-types';

const idPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const metadataShape = PropTypes.shape({
  id: idPropType.isRequired,
});

export const clientShape = PropTypes.shape({
  metadata: metadataShape,
  data: PropTypes.shape({
    name: PropTypes.string,
  }),
});

export const inputShape = PropTypes.shape({
  metadata: metadataShape,
  data: PropTypes.shape({
    name: PropTypes.string,
    client: PropTypes.shape({
      id: idPropType,
      name: PropTypes.string,
    }),
    recurring: PropTypes.string,
    firstDueDate: PropTypes.string,
    status: PropTypes.string,
    template: PropTypes.shape({
      id: idPropType,
    }),
  }),
});

export const reportShape = PropTypes.shape({
  metadata: metadataShape,
  data: PropTypes.shape({
    type: PropTypes.string,
    client: PropTypes.shape({
      id: idPropType,
      name: PropTypes.string,
    }),
    recurring: PropTypes.string,
    firstDueDate: PropTypes.string,
    referencePeriodEnd: PropTypes.string,
    status: PropTypes.string,
  }),
});

export const calendarEventShape = PropTypes.shape({
  metadata: metadataShape,
  title: PropTypes.string,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  allDay: PropTypes.bool,
});
