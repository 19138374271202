import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  IconButton,
  Menu,
  MultiButton,
  ThreeDotsIcon,
} from '@fcg-tech/regtech-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }
`;

const MenuIcon = styled(ThreeDotsIcon)`
  transform: rotate(90deg);
`;

export const EditActionGroup = ({ menuItems, canEdit, onEdit }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleToggleMenu = React.useCallback(
    () => setMenuOpen((current) => !current),
    [setMenuOpen],
  );

  const menuItemsWithCallbacks = React.useMemo(
    () =>
      menuItems.map((item) => ({
        ...item,
        callback: (...args) => {
          setMenuOpen(false);
          return item.callback(...args);
        },
      })),
    [setMenuOpen, menuItems],
  );

  return (
    <Container>
      {menuItemsWithCallbacks.length ? (
        <MultiButton
          menuAlignment="right"
          menuOpen={menuOpen}
          menuToggleComponent={() => (
            <IconButton onClick={handleToggleMenu}>
              <MenuIcon />
            </IconButton>
          )}
          onToggleMenu={handleToggleMenu}
        >
          <Menu>
            {menuItemsWithCallbacks.map(({ id, label, callback }) => (
              <Menu.Item key={id} onClick={callback}>
                {label}
              </Menu.Item>
            ))}
          </Menu>
        </MultiButton>
      ) : null}
      {canEdit && <Button onClick={onEdit}>Edit</Button>}
    </Container>
  );
};

EditActionGroup.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
};

EditActionGroup.defaultProps = {
  menuItems: [],
  canEdit: false,
  onEdit: null,
};
