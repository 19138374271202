import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useSortableTable,
} from '@fcg-tech/regtech-components';

import { constructRoute, ROUTE_CLIENT_DETAILS } from '../../../routes';
import { clientShape } from '../../../propTypes';

const EmptyTableState = styled.div`
  flex: 1;
  text-align: center;
`;

const tableCells = [
  { id: 'name', label: 'Name' },
  { id: 'internalReference', label: 'Internal reference' },
];

const sorter = (a, b, key, ascending) => {
  const aFlatten = { ...a.data, ...a.metadata };
  const bFlatten = { ...b.data, ...b.metadata };
  const aa = ascending ? aFlatten : bFlatten;
  const bb = ascending ? bFlatten : aFlatten;

  if (!aa[key]) return -1;
  if (!bb[key]) return 1;
  return aa[key].localeCompare(bb[key]);
};

const makeTableHeaderCellProps = (key, { sortedColumnKey, ...props }) => ({
  sortable: true,
  sortKey: key,
  isSorted: sortedColumnKey === key,
  ...props,
});

export const ClientsTable = ({ clients }) => {
  const history = useHistory();

  const { sortedItems, ...sortProps } = useSortableTable({
    column: 'name',
    sorter,
    items: clients,
  });

  const handleClickRow = React.useCallback(
    (id) =>
      history.push(constructRoute(ROUTE_CLIENT_DETAILS, { clientId: id })),
    [history],
  );

  return (
    <Table>
      <TableHead>
        <TableRow clickable>
          {tableCells.map(({ id, label }) => (
            <TableCell key={id} {...makeTableHeaderCellProps(id, sortProps)}>
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedItems.length ? (
          sortedItems.map(({ metadata, data }) => {
            const flattenItem = { ...metadata, ...data };
            return (
              <TableRow
                key={metadata.id}
                clickable
                onClick={() => handleClickRow(metadata.id)}
              >
                {tableCells.map(({ id: cellId }) => (
                  <TableCell key={cellId}>{flattenItem[cellId]}</TableCell>
                ))}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={tableCells.length}>
              <EmptyTableState>No clients found.</EmptyTableState>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

ClientsTable.propTypes = {
  clients: PropTypes.arrayOf(clientShape),
};

ClientsTable.defaultProps = {
  clients: [],
};
