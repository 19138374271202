import React from 'react';
import PropTypes from 'prop-types';
import { FastField as FormikFastField, Field as FormikField } from 'formik';

import { EditContext } from '../EditContext';

export const Field = ({ notFast, readOnly, ...props }) => {
  const isEditEnabled = React.useContext(EditContext);
  const Element = notFast ? FormikField : FormikFastField;

  if (readOnly) {
    return (
      <Element
        key={isEditEnabled.toString()}
        isEditEnabled={false}
        {...props}
      />
    );
  }

  return (
    <Element
      key={isEditEnabled.toString()}
      isEditEnabled={isEditEnabled}
      {...props}
    />
  );
};

Field.propTypes = {
  notFast: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Field.defaultProps = {
  notFast: false,
  readOnly: false,
};
