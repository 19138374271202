import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '@fcg-tech/regtech-components';
import fcgTheme from '@fcg-tech/fcg-theme';
import hoistFinanceTheme from '@fcg-tech/hoist-finance-theme';

import { App } from './App';
import { AuthCallbackContainer } from './containers';
import * as serviceWorker from './serviceWorker';

const themes = {
  default: fcgTheme,
  hoistFinance: hoistFinanceTheme,
};

ReactDOM.render(
  <ThemeProvider theme={themes.default}>
    <>
      <GlobalStyle />
      <Router>
        <Route path="/auth/callback" exact>
          <AuthCallbackContainer />
        </Route>
        <Route>
          <App />
        </Route>
      </Router>
    </>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
